import store from "@/store/index";
import { i18n } from "@/main";
export const copyText = async (copyContent, successMessage) => {
  if (!copyContent) return;
  await navigator.clipboard.writeText(copyContent);
  const message = successMessage ?? i18n.t("editor.transcriptionCopySuccess");
  store.dispatch("global/openAlert", { message, type: "success" });
};

export const getSentenceText = (stepSentences) => stepSentences.map(({ sentence }) => sentence).join("\n");
