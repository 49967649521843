<template>
  <div class="MultimodalSopWrapper">
    <div class="MultimodalSopWrapper__header">
      <font-awesome-icon
        class="MultimodalSopWrapper__header--close-btn"
        :icon="['far', 'xmark']"
        size="2x"
        @click="handleConfirmLeave"
      />
      <div class="MultimodalSopWrapper__header--title">
        {{ $t("mavenSop.aiSop") }}
      </div>
      <v-spacer />
      <d-activate-switch :value="isActivate" :disabled="isEmpty" @click="handleSwitchStatus" />
      <button
        @click="handleSave"
        class="text-capitalize dh-btn btn-primary ml-3"
        :disabled="!needToSave || isGenerating || isActivate"
      >
        {{ $t("all.save") }}
      </button>
    </div>
    <div class="MultimodalSopWrapper__menu">
      <button class="dh-btn btn-secondary" @click="handleCopy" :disabled="isEmpty">
        <font-awesome-icon class="text-primary" :icon="['far', 'copy']" />
        {{ $t("builder.copyText") }}
      </button>
      <button class="dh-btn btn-secondary" @click="handleDeleteClick" :disabled="isEmpty">
        <font-awesome-icon class="text-danger" :icon="['far', 'trash-can']" />
        {{ $t("builder.clearSop") }}
      </button>
    </div>
    <div class="MultimodalSopWrapper__body">
      <div class="SopLoading" v-if="isGenerating || isInitialLoading">
        <d-icon-processing-bar-flex height="8px" width="80%" />
        <div class="text">{{ isGenerating ? $t("builder.generating") : $t("player.loading") }}</div>
      </div>
      <div class="SopEmpty" v-else-if="isEmpty">
        <d-icon-empty />
        <div class="desc">{{ $t("builder.sopNoData") }}</div>
        <button class="dh-btn btn-primary" @click="genWithAI">{{ $t("quiz.generateWithAI") }}</button>
      </div>
      <div v-else class="SopItems">
        <sop-collapse-form-item
          v-for="(item, index) in sopContent"
          :key="index"
          :index="index"
          :item="item"
          :total="sopContent.length"
          :readonly="isActivate"
          @update-item="updateItem"
          @open-edit-confirm="showSopEditConfirmation = true"
        />
      </div>
    </div>

    <d-editor-confirm-popup class="notSaveAlert" v-if="showNotSaveDialog">
      <template slot="icon">
        <d-icon-save-sop />
      </template>
      <template slot="title">
        <div class="title">{{ $t("builder.sopNotSave") }}</div>
      </template>
      <template slot="content">
        <div class="desc">
          {{ $t("builder.sopNotSaveDesc") }}
        </div>
      </template>
      <template slot="action">
        <button class="dh-btn btn-outline-primary mr-2" @click="showNotSaveDialog = false">
          {{ $t("all.cancel") }}
        </button>
        <button class="dh-btn btn-primary ml-2" round @click="handleClosePopup">
          {{ $t("builder.continueWithoutSaving") }}
        </button>
      </template>
    </d-editor-confirm-popup>

    <d-confirmation-popup
      v-model="showSopDeleteConfirmation"
      redActionBtn
      :title="$t('builder.clearSop')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('maven.clearConfirm')"
      :content="$t('builder.clearSopDialogDesc')"
      :analytics="{ action: 'confirm-clear', cancel: 'confirm-clear-text-sop' }"
      @clickActionBtn="handleDeleteSop"
    ></d-confirmation-popup>
    <d-confirmation-popup
      v-model="showSopEditConfirmation"
      redActionBtn
      :title="$t('builder.sopEditAlertTitle')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('builder.popupBtnEdit')"
      :content="$t('builder.sopEditAlertDescription')"
      :analytics="{ action: 'confirm-edit', cancel: 'confirm-edit-text-sop' }"
      @clickActionBtn="handleSwitchStatus"
    ></d-confirmation-popup>
  </div>
</template>
<script>
import DResizeObserver from "@/directives/d-resize-observer";
import DIconEmpty from "@/components/icons/builder/DIconEmpty.vue";
import { generateSop } from "@/server/chat-server.js";
import { createSop, deleteSop, updateSop } from "@/server/multimodal-sop-server.js";
import { getWorkflowSop } from "@/server/workflow-server.js";
import DIconSaveSop from "@/components/icons/builder/DIconSaveSop.vue";
import DActivateSwitch from "@/components/DEditorBuilder/DActivateSwitch.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import DIconProcessingBarFlex from "@/components/icons/animated/DIconProcessingBarFlex.vue";
import DEditorConfirmPopup from "@/components/DPopup/DEditorConfirmPopup.vue";
import SopCollapseFormItem from "@/components/DBuilder/MultimodalSop/SopCollapseFormItem.vue";
import { copyText } from "@/js/common/copy";

export default {
  name: "MultimodalSop",
  directives: { DResizeObserver },
  components: {
    DActivateSwitch,
    DIconEmpty,
    DIconSaveSop,
    DConfirmationPopup,
    DIconProcessingBarFlex,
    DEditorConfirmPopup,
    SopCollapseFormItem,
  },
  data() {
    return {
      sopContent: [],
      sopId: null,
      isInitialLoading: true,
      isActivate: false,
      isGenerating: false,
      needToSave: false,
      showNotSaveDialog: false,
      showSopDeleteConfirmation: false,
      showSopEditConfirmation: false,
    };
  },
  mounted() {
    this.getWorkflowSop();
  },
  methods: {
    async genWithAI() {
      this.isGenerating = true;
      const workflowId = this.$route.params.id;
      try {
        const { ok, data } = await generateSop(workflowId);

        if (ok) {
          this.sopContent = data.items;
          this.needToSave = true;
          return;
        }
        this.$ui.openAlert({ type: "error", message: res.data.message });
      } catch (error) {
        console.error(error);
      } finally {
        this.isGenerating = false;
      }
    },
    async getWorkflowSop() {
      const workflowId = this.$route.params.id;
      const { ok, data } = await getWorkflowSop(workflowId);
      if (ok && data.item.content) {
        this.isActivate = data.item.activate;
        this.sopId = data.item.id;
        this.sopContent = data.item.content;
      }
      this.isInitialLoading = false;
    },
    async handleCopy() {
      if (this.isEmpty) return;
      const copyContent = this.sopContent
        .map((item, index) => {
          const isIntroOrConclusion = index === 0 || this.sopContent.length - 1 === index;
          const stepTitle = isIntroOrConclusion
            ? item.step
            : `${String(index).padStart(2, "0")}. ${item.step || "N/A"}`;
          let formattedText = `# ${stepTitle}`;

          if (item.description) {
            formattedText += `\n${item.description}`;
          }

          if (isIntroOrConclusion) return formattedText;

          if (item.tool) {
            formattedText += `\n\n## ${this.$t("mavenSop.toolsNeeded")}\n${item.tool}`;
          }

          if (item.safetyOrWarning) {
            formattedText += `\n\n## ${this.$t("mavenSop.safetyWarnings")}\n${item.safetyOrWarning}`;
          }

          return formattedText;
        })
        .join("\n\n---\n\n");
      await copyText(copyContent, this.$t("builder.sopCopied"));
    },
    async handleDeleteSop() {
      let ok = false;

      if (this.sopId) {
        const result = await deleteSop(this.sopId);
        ok = result.ok;
      }

      if (this.sopId ? ok : true) {
        this.sopId = null;
        this.sopContent = [];
        this.isActivate = false;
        this.needToSave = false;
      }
    },
    async handleUpsertSop() {
      const { ok, errorMessage, data } = await (this.sopId
        ? updateSop(this.sopId, { content: this.sopContent })
        : createSop({ workflowId: this.$route.params.id, content: this.sopContent }));

      if (ok) {
        if (!this.sopId) {
          this.sopId = data.item.id;
        }
        this.needToSave = false;
      }
      return { ok, errorMessage };
    },
    async handleSave() {
      this.$ui.openAlert({ type: "info", message: this.$t("editor.alertIsSaving"), manualClose: true });
      const { ok, errorMessage } = await this.handleUpsertSop();
      if (ok) {
        this.$ui.openAlert({ type: "success", message: this.$t("builder.sopSaved") });
      } else {
        this.$ui.openAlert({ type: "error", message: errorMessage });
      }
    },

    async handleSwitchStatus() {
      this.$ui.openAlert({ type: "info", message: this.$t("editor.alertIsSaving"), manualClose: true });
      if (this.needToSave) {
        await this.handleUpsertSop();
      }
      const nextActivate = !this.isActivate;
      const { ok, errorMessage } = await updateSop(this.sopId, { activate: nextActivate });
      if (ok) {
        this.isActivate = nextActivate;
        const messageKey = nextActivate
          ? "builder.sopSuccessfullyActivatedAlert"
          : "builder.sopSuccessfullyDeactivatedAlert";
        this.$ui.openAlert({ type: "success", message: this.$t(messageKey) });
      } else {
        this.$ui.openAlert({ type: "error", message: errorMessage });
      }
    },
    handleClosePopup() {
      this.$emit("close", this.isActivate);
    },
    handleConfirmLeave() {
      if (this.needToSave) {
        this.showNotSaveDialog = true;
      } else {
        this.handleClosePopup();
      }
    },
    handleDeleteClick() {
      if (this.isEmpty) return;
      this.showSopDeleteConfirmation = true;
    },
    updateItem(updatedItem, index) {
      if (!this.needToSave) {
        this.needToSave = true;
      }
      this.sopContent.splice(index, 1, updatedItem);
    },
  },
  computed: {
    isEmpty() {
      return this.sopContent.length === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.MultimodalSopWrapper {
  --menu-height: 104px;
  position: fixed;
  z-index: 10;
  inset: 0;
  background-color: var(--dGrey1-color);

  &__header {
    height: var(--header-height);
    display: flex;
    padding: 0 32px;
    align-items: center;
    &--title {
      font-size: 24px;
      color: #fff;
      margin-left: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.1;
    }

    &--close-btn {
      cursor: pointer;
      color: var(--dGrey6-color);
    }
  }

  &__menu {
    position: relative;
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: var(--menu-height);
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 16px;
      bottom: -16px;
      left: 0;
      background: linear-gradient(0deg, rgba(18, 19, 20, 0) 0%, var(--dGrey1-color) 100%);
    }
  }

  &__body {
    display: flex;
    width: 800px;
    margin: 0 auto;
    height: calc(100vh - var(--header-height) - var(--menu-height));
    color: white;
  }
}

.SopItems {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 30px;
  overflow-y: scroll;
}

.SopEmpty {
  border-radius: 8px;
  width: 100%;
  height: max-content;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--dGrey2-color);
  gap: 24px;
  .desc {
    width: 275px;
    font-size: 16px;
  }
}

.SopLoading {
  margin: 200px auto;
  .text {
    margin: 10px;
    font-size: 20px;
  }
}

.notSaveAlert {
  font-weight: 400;
  .header {
    display: flex;
    justify-content: flex-end;
    color: #9397a6;
  }
  .title {
    margin: 44px auto 8px;
    font-size: 20px;

    text-align: center;
  }
  .desc {
    color: #8d909f;
    font-size: 16px;
    max-width: 392px;
  }
  button {
    flex: 1;
  }
}
</style>
