// refs: https://docs.fontawesome.com/apis/javascript/import-icons/#icon-names
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faChevronLeft,
  faChevronRight,
  faSearch,
  faRectangleList,
  faXmark,
  faCirclePlay,
  faMessageCheck,
  faShare,
  faCopy,
  faThumbsUp,
  faThumbsDown,
  faTriangleExclamation,
  faIndustryWindows,
  faArrowRight as farArrowRight,
  faLightbulb,
  faCirclePlus,
  faPenToSquare,
  faArrowLeft,
  faTrashCan,
  faArrowProgress,
  faChevronsLeft,
  faChevronsRight,
  faMagnifyingGlass,
  faCircleInfo,
  faBallotCheck,
  faComments,
  faImage,
  faScissors,
  faRotate,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faCalendar,
  faStopwatch,
  faChartColumn,
  faCircle,
  faEllipsis,
  faSort,
  faSortUp,
  faSortDown,
  faSpinner,
  faArrowRight as fasArrowRight,
  faIndustryWindows as fasIndustryWindows,
  faChevronDown,
  faScrewdriverWrench,
  faAngleLeft,
  faPlus,
  faKey,
  faCircleXmark,
  faClipboardListCheck,
  faEye,
  faPlay,
  faBolt,
  faGear,
  faChartSimple,
  faBullseyeArrow,
  faRobot,
  faPieChart,
  faStarOfLife,
  faLockKeyhole,
} from "@fortawesome/pro-solid-svg-icons";

import { faPen } from "@fortawesome/pro-light-svg-icons";

const FaIcon = {
  install(Vue) {
    // regular
    library.add(
      faArrowProgress,
      faBallotCheck,
      faChevronLeft,
      faChevronRight,
      faSearch,
      faRectangleList,
      faXmark,
      faCirclePlay,
      faMessageCheck,
      faShare,
      faCopy,
      faThumbsUp,
      faThumbsDown,
      faTrashCan,
      faTriangleExclamation,
      faIndustryWindows,
      farArrowRight,
      faLightbulb,
      faCirclePlus,
      faPenToSquare,
      faArrowLeft,
      faChevronsLeft,
      faChevronsRight,
      faMagnifyingGlass,
      faCircleInfo,
      faPlus,
      faComments,
      faImage,
      faScissors,
      faRotate
    );

    // solid
    library.add(
      faCalendar,
      faStopwatch,
      faChartColumn,
      faCircle,
      faEllipsis,
      faSort,
      faSortUp,
      faSortDown,
      faSpinner,
      fasArrowRight,
      fasIndustryWindows,
      faChevronDown,
      faScrewdriverWrench,
      faAngleLeft,
      faKey,
      faCircleXmark,
      faClipboardListCheck,
      faEye,
      faPlay,
      faBolt,
      faGear,
      faChartSimple,
      faBullseyeArrow,
      faRobot,
      faPieChart,
      faStarOfLife,
      faLockKeyhole
    );

    // light
    library.add(faPen);
    Vue.component("font-awesome-icon", FontAwesomeIcon);
  },
};

export default FaIcon;
