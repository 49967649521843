<template>
  <div class="builder-transcription" @click="hideTooltip()">
    <v-toolbar dark color="#121314" height="80px" class="pl-2 pt-0">
      <button class="tool-bar-icon" analytics="close-translation" @click="closeTranslation()">
        <font-awesome-icon :icon="['far', 'xmark']" size="2x" />
      </button>
      <v-toolbar-title class="white--text title font-weight-regular text-capitalize">{{
        $t("builder.translation")
      }}</v-toolbar-title>
      <workflow-status-tag
        class="mx-3"
        :is-published="workflow.published"
        :status-text="workflow.published ? $t('all.published') : $t('all.unpublished')"
        :show-dot="false"
      />
      <v-spacer></v-spacer>
      <button
        class="dh-btn btn-outline-primary text-capitalize btn-width"
        @click="clickSaveBtn()"
        :disabled="!madeChanges"
        analytics="save-translation"
      >
        {{ $t("all.save") }}
      </button>
      <button
        v-if="enableText2Speech"
        class="dh-btn btn-primary btn-width text-none ml-3"
        @click="createAudioConfirmation = true"
        :disabled="!madeChanges && audioCreated"
      >
        {{ $t("builder.createAudio") }}
      </button>
    </v-toolbar>

    <!--/// Original Transcript ///-->
    <div class="original-headline">
      <button class="list-btn target-btn" disabled>
        <span class="text-no-wrap text-capitalize">{{ $t("builder.originalTranscript") }}</span>
      </button>
    </div>

    <div class="transcription-original" ref="originalWrapper">
      <div class="title-wrap original-title-wrap">
        <div
          class="title-text"
          :class="{
            'right-to-left': workflow.languageCode == 'he-il',
            'original-sentence-highlight': clickSentenceNum == 'title' && clickStepNum == -1,
          }"
          @click="(clickSentenceNum = 'title'), (clickStepNum = -1)"
        >
          {{ workflow.title }}
        </div>
      </div>

      <div
        v-for="(stepSentences, index) in sourceSentencesArray"
        :key="index"
        class="step-wrap"
        @click="clickStepNum = index"
      >
        <div class="step-num text-uppercase">{{ $t("all.step") }} {{ index + 1 }}</div>

        <div
          class="textarea-wrap"
          ref="originalTitle"
          @click="(clickSentenceNum = 'title'), scrollToSentence('addWrapper', 'addTitle', index)"
        >
          <div
            class="step-title"
            :class="{
              'original-sentence-highlight': clickSentenceNum == 'title' && clickStepNum == index,
              'right-to-left': workflow.languageCode == 'he-il',
            }"
          >
            {{ steps[index].title }}
          </div>
        </div>

        <div v-if="stepSentences.length == 0" class="textarea-wrap">
          <div class="original-sentence" :style="{ opacity: 0.3 }">({{ $t("builder.stepNoTranscript") }})</div>
        </div>
        <template v-else>
          <div
            v-for="(sentence, idx) in stepSentences"
            :key="idx"
            :ref="'originalText' + index"
            class="textarea-wrap"
            @click="(clickSentenceNum = idx), scrollToSentence('addWrapper', 'addText' + index, idx)"
          >
            <div
              rows="1"
              v-html="sentence.sentence"
              class="original-sentence"
              :class="{
                'original-sentence-highlight': idx == clickSentenceNum && clickStepNum == index,
                'right-to-left': workflow.languageCode == 'he-il',
              }"
            ></div>
          </div>
        </template>
      </div>
    </div>

    <!--/// Translations ///-->
    <div class="action-btns-div" :class="{ 'action-btns-div-border': !gotTargetSentences }">
      <div class="add-language-div">
        <button
          v-for="(language, idx) in translationList"
          :key="idx"
          class="list-btn"
          :class="{ 'target-btn': language == targetLanguage }"
          @click="switchLanguage(language)"
          :disabled="showProgressLinear || isTranslatingTitles"
        >
          <span class="text-no-wrap">{{ languageFullName[language] }}</span>
          <!--   delect confirmation  -->
          <v-menu
            v-if="language == targetLanguage"
            top
            left
            offset-y
            slide-x-transition
            class="list-btn-icon"
            elevation="0"
            :disabled="showProgressLinear || isTranslatingTitles"
          >
            <button slot="activator" @click="clickDeleteLanguages(language)">
              <icon-base color="white" height="20" width="20">
                <d-icon-trash />
              </icon-base>
            </button>

            <div class="delete-popup" :hidden="workflow.published && !editable">
              <div class="delete-popup-div">
                <button class="delete-popup-btn" analytics="cancel-delete-translation">
                  <icon-base width="36" height="36">
                    <d-icon-text-cancel />
                  </icon-base>
                  <div class="delete-popup-text text-capitalize">{{ $t("all.cancel") }}</div>
                </button>
                <div class="delete-popup-line"></div>
                <button
                  class="delete-popup-btn"
                  analytics="confirm-delete-translation"
                  @click="deleteTranslation(language)"
                >
                  <icon-base width="36" height="36">
                    <d-icon-text-delete />
                  </icon-base>
                  <div class="delete-popup-text text-capitalize">{{ $t("all.delete") }}</div>
                </button>
                <div class="arrow-down"></div>
              </div>
            </div>
          </v-menu>
        </button>
      </div>
      <v-menu
        transition="slide-y-transition"
        class="action-btn add-btn"
        :class="{
          'action-btn--disabled': disabledAddBtn,
        }"
        bottom
        offset-y
        :disabled="disabledAddBtn"
      >
        <button
          slot="activator"
          @click="clickAddlanguage()"
          @mouseover="showTooltip = true"
          @mouseleave="showTooltip = false"
          :disabled="disabledAddBtn"
        >
          <v-icon :color="disabledAddBtn ? 'white' : '#4689f4'">add</v-icon>
        </button>
        <div class="dropdown-list mt-2">
          <v-list dense dark :hidden="(this.workflow.published && !this.editable) || listedLanguages.length == 0">
            <v-list-tile
              v-for="(language, i) in listedLanguages"
              :key="i"
              @click="switchLanguage(language, 'add-new')"
              class="dropdown-list-item"
            >
              <v-list-tile-title class="body-1">{{ languageFullName[language] }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </div>
      </v-menu>
      <v-menu
        transition="slide-y-transition"
        class="more-btn action-btn"
        :class="{ 'action-btn--disabled': sourceSentencesArray.length === 0 }"
        bottom
        offset-y
        :disabled="sourceSentencesArray.length === 0"
      >
        <button slot="activator">
          <v-icon :color="sourceSentencesArray.length === 0 ? 'white' : '#4689f4'">more_vert</v-icon>
        </button>
        <div class="dropdown-list mt-2">
          <v-list dense dark>
            <v-list-tile
              class="dropdown-list-item"
              :disabled="sourceSentencesArray.length === 0"
              @click="handleCopy('original')"
            >
              <v-list-tile-title class="body-1">{{ $t("builder.copyOriginalTranscription") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              class="dropdown-list-item"
              :disabled="showProgressLinear || isTranslatingTitles || !targetLanguage"
              @click="handleCopy('selected')"
            >
              <v-list-tile-title class="body-1">{{ $t("builder.copySelectedTranscription") }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </div>
      </v-menu>
      <div
        v-if="(showTooltip && translationList.length == 0) || showTooltipOnce"
        class="add-language-tooltip"
        :class="{ 'shake-tooltip': showTooltipOnce }"
      >
        {{ $t("builder.addNewLanguageTooltip") }}
        <div class="arrow-right"></div>
      </div>
    </div>

    <div v-if="gotTargetSentences" class="transcription-add" ref="addWrapper">
      <div class="title-wrap target-title-wrap" :class="{ 'show-btn-inline': showWorkflowUntitled }">
        <div @click="(clickSentenceNum = 'title'), (clickStepNum = -1)">
          <div
            v-if="showWorkflowUntitled"
            class="title-text untitled-text"
            :class="{
              'sentence-edit-highlight': clickSentenceNum == 'title' && clickStepNum == -1,
              'right-to-left': targetLanguage === 'he-il',
            }"
          >
            [{{ $t("builder.untitled") }}]
          </div>
          <textarea
            v-else
            rows="1"
            ref="textareaTitle"
            :disabled="workflow.published && !editable"
            :placeholder="$t('editor.hintNoEmptyTitle')"
            v-model="targetWorkflowTitle"
            class="sentence-edit title-text"
            :class="{
              'sentence-edit-highlight': clickSentenceNum == 'title' && clickStepNum == -1,
              'sentence-empty': targetWorkflowTitle === '',
              'right-to-left': targetLanguage === 'he-il',
            }"
            @input="getTextareaHeight('Title', 0), (madeChanges = true), trackTextChangedByHeap()"
            @keypress.13.prevent
          ></textarea>
        </div>
        <d-button
          v-if="showTranslateTitlesBtn"
          round
          outline
          dark
          color="#4689f4"
          class="text-none body-2 mt-0 ml-0"
          :class="{ 'mt-2': !showWorkflowUntitled }"
          :disabled="isTranslatingTitles"
          @click.stop="translateAllTitles()"
        >
          <v-progress-circular
            v-if="isTranslatingTitles"
            size="16"
            width="2"
            color="grey"
            indeterminate
            class="mr-2 ml-1"
          ></v-progress-circular>
          <v-icon v-else size="20" class="mr-2">refresh</v-icon>
          {{ $t("builder.translateOnlyTitlesBtn") }}
        </d-button>
      </div>

      <div
        v-for="(stepSentences, index) in targetSentencesArray"
        :key="index"
        class="step-wrap"
        @click="clickStepNum = index"
      >
        <div class="step-num text-uppercase">{{ $t("all.step") }} {{ index + 1 }}</div>

        <div
          class="textarea-wrap"
          ref="addTitle"
          @click="(clickSentenceNum = 'title'), scrollToSentence('originalWrapper', 'originalTitle', index)"
        >
          <div
            v-if="!steps[index].titleTranslations || !steps[index].titleTranslations[targetLanguage]"
            class="step-title untitled-step-text"
            :class="{
              'sentence-edit-highlight': clickSentenceNum == 'title' && clickStepNum == index,
              'right-to-left': targetLanguage === 'he-il',
            }"
          >
            [{{ $t("builder.untitled") }}]
          </div>
          <textarea
            v-else
            rows="1"
            ref="textareaStepTitle"
            :disabled="workflow.published && !editable"
            :placeholder="$t('editor.hintNoEmptyTitle')"
            v-model="targetStepTitles[index]"
            class="sentence-edit step-title"
            :class="{
              'sentence-edit-highlight': clickSentenceNum == 'title' && clickStepNum == index,
              'sentence-empty': targetStepTitles[index] === '',
              'right-to-left': targetLanguage == 'he-il',
            }"
            @input="getTextareaHeight('StepTitle', index), (madeChanges = true), trackTextChangedByHeap()"
            @keypress.13.prevent
          ></textarea>
        </div>

        <div v-if="stepSentences.length == 0" class="textarea-wrap">
          <div class="original-sentence" :style="{ opacity: 0.3 }">({{ $t("builder.stepNoTranscript") }})</div>
        </div>
        <template v-else>
          <div
            v-for="(sentence, idx) in stepSentences"
            :key="idx"
            :ref="'addText' + index"
            class="textarea-wrap"
            @click="(clickSentenceNum = idx), scrollToSentence('originalWrapper', 'originalText' + index, idx)"
          >
            <textarea
              rows="1"
              :ref="'textarea' + index"
              :disabled="workflow.published && !editable"
              :placeholder="'(' + $t('editor.hintNoEmptySentence') + ')'"
              v-model="sentence.sentence"
              class="sentence-edit"
              :class="{
                'sentence-edit-highlight': idx == clickSentenceNum && clickStepNum == index,
                'sentence-empty': sentence.sentence == '',
                'right-to-left': targetLanguage == 'he-il',
              }"
              @input="
                replaceAllLineBreaks($event, index, idx),
                  getTextareaHeight(index, idx),
                  (madeChanges = true),
                  trackTextChangedByHeap()
              "
              @keypress.13.prevent
            ></textarea>
          </div>
        </template>
      </div>
    </div>

    <v-progress-linear
      v-if="!this.sourceSentencesArray.length"
      class="progress-linear-l"
      indeterminate
      color="grey"
      height="2"
    ></v-progress-linear>
    <v-progress-linear
      v-if="showProgressLinear && translationList.length != 0"
      class="progress-linear-r"
      indeterminate
      color="#4689f4"
      height="2"
    ></v-progress-linear>

    <!-- Saving alert -->
    <!--alert:  Your translation audio is being created. -->
    <d-alert
      v-model="isCreatingAudio"
      manualClose
      type="info"
      :message="$t('builder.alertAudioBeingCreated')"
    ></d-alert>

    <!--alert: Saving is in progress. -->
    <d-alert v-model="isSaving" type="info" manualClose :message="$t('editor.alertIsSaving')"></d-alert>

    <d-alert v-model="isNotSuccess" type="error" :message="alertText"></d-alert>
    <d-alert v-if="!isSaving" v-model="isSuccess" type="success" :message="alertText"></d-alert>

    <!--alert: Sentences cannot be empty. Please enter the sentences, then save again. -->
    <d-alert v-model="emptySentenceAlert" type="error" :message="$t('builder.alertNoEmptyContent')"></d-alert>

    <!-- switch language confirmation dialog -->
    <v-dialog dark v-model="switchConfirmation" max-width="460px">
      <v-card color="#1E1F22" class="pa-0 text-xs-left">
        <v-card-title class="title font-weight-regular grey--text">
          {{ $t("builder.switchTranslation") }}
          <v-spacer></v-spacer>
          <v-icon small color="grey" @click="closeSwitchConfirmation()">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h3 class="font-weight-regular pb-3">
            <span v-html="$t('builder.popupMsgSwitchTranslation', { lang: languageFullName[targetLanguage] })"></span>
          </h3>
        </v-card-text>
        <v-card-actions class="pr-3 pb-4">
          <v-spacer></v-spacer>
          <d-button round outline class="caption mr-2 text-none" color="white" @click="switchWithoutSave()">{{
            $t("builder.popupBtnNoSwitch")
          }}</d-button>
          <d-button round depressed class="caption white--text text-none" color="#4689f4" @click="switchWithSave()">{{
            $t("builder.popupBtnYesSwitch")
          }}</d-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- close editor confirmation dialog -->
    <!-- You have unsaved changes on your translation. If you continue you
    <b>will lose all changes</b>. Are you sure?-->
    <d-confirmation-popup
      v-model="closeConfirmation"
      :title="$t('builder.closeTranslation')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('editor.popupBtnClose')"
      :content="$t('builder.popupMsgCloseTranslation')"
      @clickActionBtn="cancelChanges()"
    ></d-confirmation-popup>

    <!-- Generate Voice dialog -->
    <d-confirmation-popup
      v-model="createAudioConfirmation"
      :title="$t('builder.createAudio')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('builder.popupBtnYesCreate')"
      :content="$t('builder.popupMsgCreateAudio')"
      @clickActionBtn="createAudio()"
    ></d-confirmation-popup>

    <!-- Edit workflow confirmation dialog -->
    <!-- If you go into edit mode, your workflow will automatically
    be unpublished , are you sure you want to continue?-->
    <d-confirmation-popup
      v-model="showEditWorkflowPopup"
      :title="$t('builder.editWorkflow')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('builder.popupBtnEdit')"
      :content="$t('builder.popupMsgEditWorkflow')"
      @clickActionBtn="$emit('editWorkflow')"
    ></d-confirmation-popup>
  </div>
</template>

<script>
import MixinDB from "./MixinDB.vue";
import MixinSchedule from "./MixinSchedule.vue";
import MixinUser from "./MixinUser.vue";
import axios from "axios";
import DAlert from "./ui_components/DAlert.vue";
import IconBase from "./IconBase.vue";
import DIconTrash from "./icons/DIconTrash.vue";
import DIconTextDelete from "./icons/DIconTextDelete.vue";
import DIconTextCancel from "./icons/DIconTextCancel.vue";
import DIconAlert from "./icons/DIconAlert.vue";
import DConfirmationPopup from "./DPopup/DConfirmationPopup.vue";
import Analytics from "@/js/analytics/analytics";
import {
  getTranslationSentence,
  saveTranslationWorkflowTitle,
  saveTranslationStepTitle,
} from "@/server/translate-server.js";
import { getTranslationLanguageCode } from "@/js/workflow/checkLanguage.js";
import {
  getLanguageCodeListByEnv,
  getLanguageFullEnglishName,
  sortLanguageCodeByFullName,
} from "@/js/workflow/language.js";
import { debounce } from "lodash-es";
import { loggingError } from "@/server/error-log-server.js";
import { copyText, getSentenceText } from "@/js/common/copy";

export default {
  name: "BuilderTranslation",
  watch: {
    steps: function () {
      this.debounceCheckTranslateTitlesBtn();
    },
    workflow: function () {
      this.debounceCheckTranslateTitlesBtn();
    },
    targetLanguage: function () {
      if (this.targetLanguage) {
        this.getTargetTitles(this.targetLanguage);
      }
    },
    targetSentencesArray: function () {
      this.$nextTick(() => {
        this.updateAllTextareaHeight();
      });

      if (!Array.isArray(this.targetSentencesArray) || !this.targetSentencesArray.length) {
        this.gotTargetSentences = false;
      } else if (!Array.isArray(this.targetSentencesArray[0]) || !this.targetSentencesArray[0].length) {
        this.gotTargetSentences = false;
      } else {
        this.gotTargetSentences = true;
        this.showProgressLinear = false;
      }
    },
    showTranslation: function () {
      if (this.showTranslation) {
        this.$nextTick(() => {
          this.updateAllTextareaHeight();
        });
        if (this.translationList.length == 0) {
          this.showTooltipOnce = true;
        }
      }
    },
    isSavingBuilder: function () {
      this.isSaving = this.isSavingBuilder;
    },
    isSuccessBuilder: function () {
      this.isSuccess = this.isSuccessBuilder;
    },
    isNotSuccessBuilder: function () {
      this.isNotSuccess = this.isNotSuccessBuilder;
    },
    alertTextBuilder: function () {
      this.alertText = this.alertTextBuilder;
    },
    translationList: function () {
      if (this.translationList.length != 0 && !this.translationList.includes(this.targetLanguage)) {
        this.getTargetLanguage(this.translationList[0]);
      } else if (this.translationList.length == 0) {
        this.targetLanguage = "";
        this.targetSentencesArray = [];
      }
    },
  },
  props: {
    steps: {
      type: Array,
    },
    workflow: {
      type: Object,
    },
    translationList: {
      type: Array,
    },
    showTranslation: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
    },
    alertTextBuilder: {
      type: String,
    },
    isSavingBuilder: {
      type: Boolean,
    },
    isSuccessBuilder: {
      type: Boolean,
    },
    isNotSuccessBuilder: {
      type: Boolean,
    },
  },
  async mounted() {
    const self = this;
    this.sourceLanguage = this.workflow.languageCode;
    this.targetLanguage = this.translationList[0];
    await this.getSourceLanguage(this.sourceLanguage);
    if (self.noSentencesStep.length) {
      alert("steps with no transcript is skipped.");
      this.getTargetLanguage(this.targetLanguage);
    }
    if (this.targetLanguage) {
      this.getTargetLanguage(this.targetLanguage);
      this.getTargetTitles(this.targetLanguage);
    }
    this.$nextTick(() => {
      window.addEventListener("resize", this.updateAllTextareaHeight);
    });
  },
  created() {
    if (this.$enableText2Speech) {
      this.enableText2Speech = true;
    }
  },
  components: {
    WorkflowStatusTag: () => import("@/components/DEditorBuilder/WorkflowStatusTag.vue"),
    DAlert,
    IconBase,
    DIconTrash,
    DIconTextDelete,
    DIconTextCancel,
    DIconAlert,
    DConfirmationPopup,
  },
  mixins: [MixinDB, MixinSchedule, MixinUser],
  data() {
    return {
      textareaText: "",
      transcriptions: {},
      targetLanguage: "",
      sourceLanguage: "",
      sourceSentencesArray: [],
      targetSentencesArray: [],
      clickStepNum: -1,
      clickSentenceNum: "",
      addedLanguages: [],
      noVoiceLanguages: ["bg"],
      madeChanges: false,
      closeConfirmation: false,
      switchConfirmation: false,
      clickedLanguage: "",
      showEditWorkflowPopup: false,
      createAudioConfirmation: false,
      transcriptionSentences: {}, //keeps track the sentences for each transcription
      gotTargetSentences: false,
      showProgressLinear: false,
      isSaving: false,
      isSuccess: false,
      isNotSuccess: false,
      alertText: "",
      test: "",
      audioCreated: false,
      isCreatingAudio: false,
      voices: [], //keeps track what was generated,
      transcriptionStorage: {}, //stores all previously downloaded transcriptions
      showTooltip: false,
      showTooltipOnce: false,
      emptySentenceAlert: false,
      noSentencesStep: [],
      enableText2Speech: false,
      targetWorkflowTitle: "",
      targetStepTitles: [],
      isTranslatingTitles: false,
      showTranslateTitlesBtn: false,
      trackedByHeap: false,
    };
  },
  methods: {
    handleCopy(type) {
      const sentencesOfSteps = type === "original" ? this.sourceSentencesArray : this.targetSentencesArray;
      const content = sentencesOfSteps.reduce((resultText, stepSentences, currentIndex) => {
        if (stepSentences.length === 0) return resultText;
        const stepSentenceText = getSentenceText(stepSentences);
        const currentStep = this.steps[currentIndex];
        return (
          resultText +
          `\nSTEP ${currentIndex + 1} ${
            type === "original" ? currentStep.title : currentStep.titleTranslations[this.targetLanguage]
          }\n` +
          stepSentenceText
        );
      }, "");
      copyText(content);
    },
    ///////// translate titles /////////
    checkTranslateTitlesBtn() {
      const target = this.targetLanguage;
      if (!target) {
        this.showTranslateTitlesBtn = false;
        return;
      }
      let hasEmptyTitle = false;
      if (!this.workflow.titleTranslations || !this.workflow.titleTranslations[target]) {
        hasEmptyTitle = true;
      }
      for (let i = 0; i < this.steps.length; i++) {
        if (!this.steps[i].titleTranslations || !this.steps[i].titleTranslations[target]) {
          hasEmptyTitle = true;
          break;
        }
      }
      this.showTranslateTitlesBtn = hasEmptyTitle;
    },
    debounceCheckTranslateTitlesBtn: debounce(function () {
      this.checkTranslateTitlesBtn();
    }, 1000),
    async getTargetTitles(language, todo) {
      // workflow title
      if (this.workflow.titleTranslations) {
        this.targetWorkflowTitle = this.workflow.titleTranslations[language] || "";
      } else {
        this.targetWorkflowTitle = "";
      }
      // step titles
      this.targetStepTitles = [];
      for (let step of this.steps) {
        let title = "";
        if (step.titleTranslations) {
          title = step.titleTranslations[language] || "";
        }
        this.targetStepTitles.push(title);
      }
      this.checkTranslateTitlesBtn();
      // get translated title when add new translation
      if (todo === "add-new") {
        await this.translateTargetTitles(language);
        await this.saveTranslatedTitles();
        this.trackLangSelectedByHeap(language);
      }
    },
    trackLangSelectedByHeap(language) {
      const languageEnglishName = getLanguageFullEnglishName(this.$dictionary)[language];
      Analytics.setTrack({
        category: "BuilderMain",
        action: "Edit Translation",
        name: "Select Translation Language",
        params: {
          dimension3: languageEnglishName,
        },
      });
    },
    async translateTargetTitles(language) {
      this.isTranslatingTitles = true;
      const source = this.sourceLanguage;
      const target = language;
      let isSuccess = true;
      if (!this.targetWorkflowTitle) {
        const result = await getTranslationSentence(this.workflow.title, source, target);
        this.targetWorkflowTitle = result.sentence;
        this.$nextTick(() => {
          this.updateAllTextareaHeight("workflow-title");
        });
        if (!result.ok) {
          isSuccess = false;
        }
      }
      const self = this;
      let newStepTitles = [];
      for (let i = 0; i < self.steps.length; i++) {
        if (!self.targetStepTitles[i]) {
          const resultStep = await getTranslationSentence(self.steps[i].title, source, target);
          newStepTitles.push(resultStep.sentence);
          if (!resultStep.ok) {
            isSuccess = false;
          }
        } else {
          newStepTitles.push(self.targetStepTitles[i]);
        }
      }
      if (!isSuccess) {
        alert("title failed.");
      }
      this.targetStepTitles = newStepTitles;
      this.showTranslateTitlesBtn = false;
      this.$nextTick(() => {
        this.isTranslatingTitles = false;
        this.updateAllTextareaHeight("step-title");
      });
    },
    async translateAllTitles() {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
        return;
      }
      this.isTranslatingTitles = true;
      let isSuccess = true;
      try {
        await this.translateAndSaveWorkflowAllTitles();
        await this.translateAndSaveStepsAllTitles();
      } catch (error) {
        isSuccess = false;
        loggingError(error);
      } finally {
        this.isTranslatingTitles = false;
        if (isSuccess) {
          this.alertText = this.$t("builder.alertTranslationsSaved");
          this.isSuccess = true;
        } else {
          this.alertText = this.$t("builder.alertTranslationsSaveFailed");
          this.isNotSuccess = true;
        }
      }
    },
    async translateAndSaveWorkflowAllTitles() {
      let addedNew = false;
      let isSuccess = true;
      const source = this.sourceLanguage;
      const langList = this.workflow.translations;
      const oldTranslations = this.workflow.titleTranslations || {};
      let titleTranslations = {};
      for (let idx = 0; idx < langList.length; idx++) {
        const lang = langList[idx];
        let needNew = false;
        if (oldTranslations[lang]) {
          if (lang === this.targetLanguage) {
            if (this.targetWorkflowTitle) {
              titleTranslations[lang] = this.targetWorkflowTitle;
            } else {
              needNew = true;
            }
          } else {
            titleTranslations[lang] = oldTranslations[lang];
          }
        } else {
          needNew = true;
        }
        if (needNew) {
          const target = lang;
          const result = await getTranslationSentence(this.workflow.title, source, target);
          if (result.ok) {
            titleTranslations[lang] = result.sentence;
            addedNew = true;
          } else {
            isSuccess = false;
          }
        }
      }
      this.targetWorkflowTitle = titleTranslations[this.targetLanguage];
      this.$nextTick(() => {
        this.updateAllTextareaHeight("workflow-title");
      });
      if (!addedNew) {
        return isSuccess;
      }
      const workflowId = this.workflow.id;
      const saveResult = await saveTranslationWorkflowTitle(workflowId, titleTranslations);
      if (!saveResult.ok) {
        isSuccess = false;
      }
      return isSuccess;
    },
    async translateAndSaveStepsAllTitles() {
      let addedNew = false;
      let isSuccess = true;
      const source = this.sourceLanguage;
      const langList = this.workflow.translations;
      for (let idx = 0; idx < this.steps.length; idx++) {
        const step = this.steps[idx];
        let titleTranslations = {};
        const oldTranslations = step.titleTranslations || {};
        for (let i = 0; i < langList.length; i++) {
          const lang = langList[i];
          let needNew = false;
          if (oldTranslations[lang]) {
            if (lang === this.targetLanguage) {
              if (this.targetStepTitles[idx]) {
                titleTranslations[lang] = this.targetStepTitles[idx];
              } else {
                needNew = true;
              }
            } else {
              titleTranslations[lang] = oldTranslations[lang];
            }
          } else {
            needNew = true;
          }
          if (needNew) {
            const target = lang;
            const result = await getTranslationSentence(step.title, source, target);
            if (result.ok) {
              titleTranslations[lang] = result.sentence;
              addedNew = true;
            } else {
              isSuccess = false;
            }
          }
        }
        this.targetStepTitles[idx] = titleTranslations[this.targetLanguage];
        this.$nextTick(() => {
          this.updateAllTextareaHeight("step-title");
        });
        if (!addedNew) {
          continue;
        }
        const stepId = step.id;
        const resultStep = await saveTranslationStepTitle(stepId, titleTranslations);
        if (!resultStep.ok) {
          isSuccess = false;
        }
      }
      return isSuccess;
    },
    async saveWorkflowTitle(todo, language) {
      const target = language;
      const oldTranslations = this.workflow.titleTranslations || {};
      const noChanges = this.targetWorkflowTitle === oldTranslations[target];
      if (todo === "save" && noChanges) {
        return;
      }
      const workflowId = this.workflow.id;
      let titleTranslations = {};
      const langList = this.workflow.translations;
      langList.forEach((lang) => {
        if (todo === "save") {
          if (lang === target) {
            titleTranslations[lang] = this.targetWorkflowTitle;
          } else if (oldTranslations[lang]) {
            titleTranslations[lang] = oldTranslations[lang];
          }
        } else if (todo === "delete" && lang !== target && oldTranslations[lang]) {
          titleTranslations[lang] = oldTranslations[lang];
        }
      });
      const result = await saveTranslationWorkflowTitle(workflowId, titleTranslations);
      if (result.ok) {
        this.alertText = this.$t("builder.alertTranslationsSaved");
        this.isSuccess = true;
      } else {
        this.alertText = this.$t("builder.alertTranslationsSaveFailed");
        this.isNotSuccess = true;
      }
    },
    async saveStepTitles(todo, language) {
      let isSuccess = true;
      const target = language;
      const langList = this.workflow.translations;
      for (let idx = 0; idx < this.steps.length; idx++) {
        let titleTranslations = {};
        const oldTranslations = this.steps[idx].titleTranslations || {};
        const noChanges = oldTranslations[target] && oldTranslations[target] === this.targetStepTitles[idx];
        if (todo === "save" && noChanges) {
          continue;
        }
        langList.forEach((lang) => {
          if (todo === "save") {
            if (lang === target) {
              titleTranslations[lang] = this.targetStepTitles[idx];
            } else if (oldTranslations[lang]) {
              titleTranslations[lang] = oldTranslations[lang];
            }
          } else if (todo === "delete") {
            if (lang !== target && oldTranslations[lang]) {
              titleTranslations[lang] = oldTranslations[lang];
            }
          }
        });
        const stepId = this.steps[idx].id;
        const resultStep = await saveTranslationStepTitle(stepId, titleTranslations);
        if (!resultStep.ok) {
          isSuccess = false;
        }
      }
      if (isSuccess) {
        this.alertText = this.$t("builder.alertTranslationsSaved");
        this.isSuccess = true;
      } else {
        this.alertText = this.$t("builder.alertTranslationsSaveFailed");
        this.isNotSuccess = true;
      }
    },
    saveTranslatedTitles() {
      const language = this.targetLanguage;
      this.saveWorkflowTitle("save", language);
      this.saveStepTitles("save", language);
    },
    deleteTranslatedTitles(language) {
      this.saveWorkflowTitle("delete", language);
      this.saveStepTitles("delete", language);
    },
    async deleteTranslation(language) {
      await this.deleteTranslatedSentences(language);
      await this.deleteTranslatedTitles(language);

      const languageEnglishName = getLanguageFullEnglishName(this.$dictionary)[language];
      Analytics.setTrack({
        category: "BuilderMain",
        action: "Edit Translation",
        name: "Select Translation Language",
        params: {
          dimension3: languageEnglishName,
        },
      });
    },
    ///////// translate titles end /////////
    getSourceLanguage(sourceLanguage) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getAllTranscriptions(sourceLanguage)
          .then(() => {
            self.updateSourceSentencesArray();
            resolve();
          })
          .catch((err) => {
            self.isNotSuccess = true;
            self.alertText = err;
            self.showProgressLinear = false;
            console.log(err);
            reject(err);
          });
      });
    },
    switchLanguage(language, todo) {
      if (!this.hasEmptySentence()) {
        this.clickedLanguage = language;
        if (this.clickedLanguage != this.targetLanguage && this.clickedLanguage != "") {
          if (this.madeChanges) {
            this.switchConfirmation = true;
          } else {
            this.getTargetLanguage(language);
            this.getTargetTitles(language, todo);
          }
        }
      }
    },
    closeSwitchConfirmation() {
      this.clickedLanguage = "";
      this.switchConfirmation = false;
    },
    switchWithoutSave() {
      const language = this.clickedLanguage;

      this.getTargetLanguage(this.clickedLanguage);
      this.clickedLanguage = "";
      this.switchConfirmation = false;
      this.madeChanges = false;

      this.trackedByHeap = false;
      this.trackLangSelectedByHeap(language);
    },
    switchWithSave() {
      const language = this.clickedLanguage;

      this.saveChanges();
      this.getTargetLanguage(this.clickedLanguage);
      this.clickedLanguage = "";
      this.switchConfirmation = false;

      this.trackLangSelectedByHeap(language);
    },
    hideTooltip() {
      if (this.showTooltipOnce) {
        this.showTooltipOnce = false;
      }
    },
    clickDeleteLanguages(language) {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
      }
    },
    clickAddlanguage() {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
      }
    },
    closeTranslation() {
      if (!this.madeChanges) {
        this.$emit("closeTranslation");
      } else {
        this.closeConfirmation = true;
      }
    },
    createAudio() {
      const self = this;
      const promises = [];
      const languagesToGenerate = Object.keys(this.transcriptionSentences);
      if (!languagesToGenerate.length) {
        self.isNotSuccess = true;
        self.alertText = this.$t("builder.alertNoAudioGenerate");
        return;
      }
      self.isCreatingAudio = true;
      languagesToGenerate.forEach((language) => {
        if (self.noVoiceLanguages.includes(language)) {
          alert(self.languageFullName[language] + " voice is not supported.");
        }
        if (!self.voices.includes(language) && !self.noVoiceLanguages.includes(language)) {
          //only generate voice if the language has not been generated & skip those voice language are not supported
          self.voices.push(language);
          self.steps.forEach((step) => {
            const transcriptionId = step.transcriptions[language];
            promises.push(self.createText2Speech(language, step.id, transcriptionId));
          });
        }
      });
      Promise.all(promises)
        .then(() => {
          self.audioCreated = true;
          setTimeout(() => {
            self.isCreatingAudio = false;
            self.isSuccess = true;
            let languagesGenerated;
            self.voices.forEach((voice) => {
              languagesGenerated = self.languageFullName[voice] + " ";
            });
            self.alertText = languagesGenerated + " " + this.$t("builder.alertAudioCreated");
            // " audio successfully created.";
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            self.isCreatingAudio = false;
            self.isNotSuccess = true;
            self.alertText = this.$t("builder.alertAudioFailed");
            // "Failed to create your translation audio.";
          }, 1000);
          loggingError(err);
        });
    },
    createText2Speech(language, stepId, transcriptionId) {
      return new Promise(
        function (resolve, reject) {
          if (language && stepId) {
            const text2SpeechId = this.generateDocId("text2Speech");
            const data = {
              id: text2SpeechId,
              level: "STEP",
              stepId: stepId,
              organization: this.$store.state.userProfile[0].organization,
              transcriptionId: transcriptionId,
              languageCode: language,
              status: "scheduled",
            };
            this.addDocument("text2Speech", text2SpeechId, data)
              .then(() => {
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            reject("language or stepId missing");
          }
        }.bind(this)
      );
    },
    cancelChanges() {
      this.closeConfirmation = false;
      this.madeChanges = false;
      this.trackedByHeap = false;
      this.addedLanguages = [];
      this.$emit("closeTranslation");
    },
    clickSaveBtn() {
      //stop the saving if has empty sentence
      if (!this.hasEmptySentence()) {
        this.saveTranslatedTitles();
        this.saveChanges();
        Analytics.setTrack({
          category: "BuilderMain",
          action: "Edit Translation",
          name: "Save Translation",
        });
      }
    },
    hasEmptySentence() {
      let hasEmpty = false;
      if (this.translationList.length === 0) {
        return hasEmpty;
      }
      hasEmpty = this.hasEmptyTitle();
      if (!hasEmpty) {
        for (let i = 0; i < this.targetSentencesArray.length; i++) {
          for (let k = 0; k < this.targetSentencesArray[i].length; k++) {
            if (this.targetSentencesArray[i][k].sentence == "") {
              hasEmpty = true;
              break;
            }
          }
        }
      }
      this.emptySentenceAlert = hasEmpty;
      return hasEmpty;
    },
    hasEmptyTitle() {
      let hasEmpty = false;
      if (this.showTranslateTitlesBtn) {
        return hasEmpty;
      }
      if (!this.targetWorkflowTitle) {
        hasEmpty = true;
        return hasEmpty;
      }
      for (let i = 0; i < this.targetStepTitles.length; i++) {
        if (!this.targetStepTitles[i]) {
          hasEmpty = true;
          break;
        }
      }
      return hasEmpty;
    },
    saveChanges() {
      const languagesToSave = Object.keys(this.transcriptionSentences);
      const self = this;
      self.isSaving = true;
      const promises = [];
      const subtitlesPromises = [];
      languagesToSave.forEach((language) => {
        for (let i = 0; i < this.transcriptionSentences[language].length; i++) {
          self.transcriptions[language][i].sentences = JSON.stringify(this.transcriptionSentences[language][i]);
          //save to db
          if (self.transcriptions[language][i].id) {
            promises.push(
              self.updateDocument(
                "transcriptions",
                self.transcriptions[language][i].id,
                self.transcriptions[language][i]
              )
            );
            //also save to transcriptionStorage
            self.transcriptionStorage[self.transcriptions[language][i].id] = self.transcriptions[language][i];
          }
        }
      });
      //save translationList to workflow
      promises.push(
        self.updateDocument("workflows", self.workflow.id, {
          translations: self.translationList,
        })
      );
      Promise.all(promises)
        .then(() => {
          //create subtitles after translations/transcriptions are saved
          languagesToSave.forEach((language) => {
            self.steps.forEach((step) => {
              subtitlesPromises.push(self.createSubtitles(language, step.id));
              const transcriptionId = step.transcriptions[language];
            });
          });
          Promise.all(subtitlesPromises)
            .then(() => {
              self.isSaving = false;
              self.madeChanges = false;
              self.trackedByHeap = false;
              self.alertText = this.$t("builder.alertTranslationsSaved");
              self.isSuccess = true;
              setTimeout(() => {
                self.isSuccess = false;
              }, 3000);
            })
            .catch((err) => {
              console.log(err);
              self.isSaving = false;
              self.alertText = this.$t("builder.alertTranslationsSaveFailed");
              self.isNotSuccess = true;
              loggingError(err);
            });
        })
        .catch((err) => {
          console.log(err);
          self.isSaving = false;
          self.alertText = this.$t("builder.alertTranslationsSaveFailed");
          self.isNotSuccess = true;
          loggingError(err);
        });
    },
    scrollToSentence(part, ref, idx) {
      if (this.$refs[ref][idx]) {
        const offsetTop = this.$refs[ref][idx].offsetTop;
        const padding = this.$refs[part].offsetHeight / 3;
        const container = this.$refs[part];
        container.scrollTo({
          top: offsetTop - padding,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    getTargetLanguage(language) {
      //prevent adding new language while loading
      if (this.showProgressLinear) {
        alert("please wait for the previous translation to finish.");
        return;
      }
      this.targetLanguage = language;
      this.gotTargetSentences = false;
      if (this.targetLanguage) {
        this.showProgressLinear = true;
      }
      this.getTranscriptions(this.targetLanguage);

      this.$emit("addTranslation", language);
      this.audioCreated = false;
    },
    removeSubtitles(language, step) {
      step.subtitles = Array.isArray(step.subtitles)
        ? step.subtitles.filter((subtitle) => subtitle.language !== language)
        : [];
    },
    deleteTranslatedSentences(language) {
      if (this.showProgressLinear) {
        alert("please do not delete while translation is being loaded.");
        return;
      }
      //TODO: ADD CONFIRMATION BEFORE REMOVING LANGUAGE
      const self = this;
      delete self.transcriptionSentences[language];
      self.targetSentencesArray.length = 0;
      self.targetSentencesArray = [];
      self.targetSentencesArray.splice();
      //remove language from transcriptions
      delete self.transcriptions[language];
      //remove language from the steps
      self.steps.forEach((step) => {
        const transcriptions = step.transcriptions;
        delete transcriptions[language];
        self.removeSubtitles(language, step);
        self
          .updateDocument("steps", step.id, {
            transcriptions: step.transcriptions,
            subtitles: step.subtitles,
          })
          .then(() => {
            self.$emit("removeTranslation", language);
          });
      });
      self.madeChanges = false;
      self.trackedByHeap = false;
    },
    //this gets transcription for either cache or load/create from backend
    getTranscriptions(language) {
      const self = this;
      if (this.transcriptions[language]) {
        if (this.transcriptions[language].length > 0) {
          self.updateTargetSentencesArray();
        }
      } else {
        this.getAllTranscriptions(language)
          .then(() => {
            self.updateTargetSentencesArray();
          })
          .catch((err) => {
            self.isNotSuccess = true;
            self.alertText = err;
            self.showProgressLinear = false;
            console.log(err);
            loggingError(err);
          });
      }
    },
    updateTargetSentencesArray() {
      if (this.targetLanguage) {
        this.targetSentencesArray = [];
        const transcriptions = this.transcriptions[this.targetLanguage];
        for (let i = 0; i < transcriptions.length; i++) {
          this.targetSentencesArray.push(this.getSentences(transcriptions[i]));
        }
        this.targetSentencesArray.splice();
        this.showProgressLinear = false;
        this.transcriptionSentences[this.targetLanguage] = JSON.parse(JSON.stringify(this.targetSentencesArray)); //must hard copy to avoid rendering issue when updating text
      }
    },
    updateSourceSentencesArray() {
      this.sourceSentencesArray = [];
      const transcriptions = this.transcriptions[this.sourceLanguage];
      for (let i = 0; i < transcriptions.length; i++) {
        this.sourceSentencesArray.push(this.getSentences(transcriptions[i]));
      }
    },
    getSentences(transcription) {
      if (transcription.sentences) {
        return JSON.parse(transcription.sentences);
      } else {
        return [];
      }
    },
    //this loads or creates transcriptions from all of the steps depending on availability
    async getAllTranscriptions(languageCode) {
      if (!languageCode) return;
      const self = this;
      return new Promise(
        async function (resolve, reject) {
          const transcriptionArray = [];
          for (let i = 0; i < this.steps.length; i++) {
            //skip steps with no sentence
            if (this.noSentencesStep[i]) {
              continue;
            }
            //check if each step already has the transcription/translation available
            let transcriptionId = this.steps[i].transcriptions[languageCode];
            if (!transcriptionId) {
              //create translation/transcription if it's not already available
              if (self.sourceSentencesArray[i]) {
                if (self.sourceSentencesArray[i].length > 0) {
                  //skip if the step has no sentence
                  const transcription = await self
                    .createTranscription(self.steps[i].id, languageCode, self.sourceSentencesArray[i])
                    .catch((err) => {
                      reject(err);
                      return;
                    });
                  if (!transcription.sentences.length) {
                    transcription.sentences = "N/A";
                  }
                  transcriptionArray.push(transcription);
                  self.transcriptionStorage[transcription.id] = transcription;
                } else {
                  console.log("source step has no sentences.");
                }
              }
            } else {
              let transcription = await self.getTranscription(transcriptionId).catch((err) => {
                reject(err);
              });
              if (transcription) {
                //skip steps with no sentence
                const sentences = JSON.parse(transcription.sentences);
                if (sentences.length) {
                  transcriptionArray.push(transcription);
                  self.transcriptionStorage[transcription.id] = transcription;
                } else {
                  self.noSentencesStep[i] = true;
                }
              }
            }
            // }
          }
          this.transcriptions[languageCode] = transcriptionArray;
          resolve();
        }.bind(this)
      );
    },
    storeStepTranscription(stepId, languageCode, sentences) {
      const self = this;
      const transcription = JSON.stringify(sentences);
      return new Promise(function (resolve, reject) {
        const id = self.generateDocId("transcriptions");
        const data = {
          id: id,
          language: languageCode,
          sentences: transcription,
          stepId: stepId,
          organization: self.$store.state.userProfile[0].organization,
        };
        self
          .addDocument("transcriptions", id, data)
          .then(() => {
            self
              .updateMap("steps", stepId, { language: languageCode, id: id })
              .then(() => {
                //update local steps with the new transcription id; this is needed for creating audio
                for (let i = 0; i < self.steps.length; i++) {
                  if (self.steps[i].id == stepId) {
                    self.steps[i].transcriptions[languageCode] = id;
                  }
                }
                resolve(data);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createTranscription(stepId, languageCode, sentences) {
      const self = this;
      let cleanSentences = [];
      //send only sentences, remove words
      sentences.forEach((sentence) => {
        cleanSentences.push({
          sentence: sentence.sentence,
          startTime: sentence.startTime,
          endTime: sentence.endTime,
        });
      });
      return new Promise(async function (resolve, reject) {
        const token = await self.getIdToken();
        const data = {
          token: token,
          mode: "sentence",
          sentences: JSON.stringify(cleanSentences),
          source: getTranslationLanguageCode(self.workflow.languageCode),
          target: getTranslationLanguageCode(languageCode),
          backendType: self.$backendType,
          organization: self.$store.state.userProfile[0].organization,
        };
        axios
          .post(self.$service.language + "translate", data)
          .then(function (response) {
            const translatedSentences = response.data;
            //save translated transcription to step
            self
              .storeStepTranscription(stepId, languageCode, translatedSentences)
              .then((transcription) => {
                //add subtitles
                self.createSubtitles(languageCode, stepId);
                resolve(transcription);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch(function (error) {
            const stepNumber = self.steps.indexOf(stepId) + 1;
            console.log("createTranscription error: " + error);
            reject("failed to create translation for Step " + stepNumber);
          });
      });
    },
    getTranscription(id) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocument("transcriptions", id)
          .then((doc) => {
            resolve(doc);
          })
          .catch((err) => {
            reject("failed to load transcription");
          });
      });
    },
    updateAllTextareaHeight(part) {
      // All Sentences
      if ((!part || part === "sentences") && this.gotTargetSentences) {
        for (let index = 0; index < this.targetSentencesArray.length; index++) {
          for (let idx = 0; idx < this.targetSentencesArray[index].length; idx++) {
            this.getTextareaHeight(index, idx);
          }
        }
      }
      // Workflow Title
      if (!part || part === "workflow-title") {
        this.getTextareaHeight("Title", 0);
      }
      // Step Titles
      if (!part || part === "step-title") {
        const totalStepNum = this.steps.length;
        for (let idx = 0; idx < totalStepNum; idx++) {
          this.getTextareaHeight("StepTitle", idx);
        }
      }
    },
    getTextareaHeight(index, idx) {
      const ref = "textarea" + index;
      if (index === "Title" && this.$refs[ref]) {
        this.$refs[ref].style.height = "auto";
        const textareaHeight = this.$refs[ref].scrollHeight;
        this.$refs[ref].style.height = textareaHeight + "px";
      } else if (this.$refs[ref][idx]) {
        this.$refs[ref][idx].style.height = "auto";
        const textareaHeight = this.$refs[ref][idx].scrollHeight;
        this.$refs[ref][idx].style.height = textareaHeight + "px";
      }
    },
    replaceAllLineBreaks(event, stepIndex, sentenceIndex) {
      //mouse events and keyboard events
      event.preventDefault();
      const textarea = event.target;
      textarea.value = textarea.value.replace(/\n/g, "");
      textarea.value = textarea.value.replace(/\r\n/g, "");
      this.transcriptionSentences[this.targetLanguage][stepIndex][sentenceIndex].sentence = textarea.value;
    },
    trackTextChangedByHeap() {
      if (this.trackedByHeap) return;

      this.trackedByHeap = true;
      Analytics.setTrack({
        category: "BuilderMain",
        action: "Edit Translation",
        name: "Edit Translation Text",
      });
    },
  },
  computed: {
    disabledAddBtn() {
      return (
        this.sourceSentencesArray.length === 0 ||
        this.showProgressLinear ||
        this.listedLanguages.length === 0 ||
        this.isTranslatingTitles
      );
    },
    showWorkflowUntitled() {
      return !this.workflow.titleTranslations || !this.workflow.titleTranslations[this.targetLanguage];
    },
    languageFullName() {
      let locale = this.$i18n.locale;
      if (locale == "en") locale = "en-us";
      return this.$dictionary[locale].languageCode;
    },
    supportedLanguages() {
      const languageCodeList = getLanguageCodeListByEnv(this.$settings.translate, "lang");
      return sortLanguageCodeByFullName(languageCodeList, this.languageFullName);
    },
    listedLanguages() {
      return this.supportedLanguages.filter((language) => {
        return this.workflow.languageCode !== language && !this.translationList.includes(language);
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateAllTextareaHeight);
  },
};
</script>

<style scoped lang="scss">
.builder-transcription {
  --action-height: 110px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0c0c0e;
}
.tool-bar-icon {
  color: #ffffff;
  padding: 3px;
  margin-right: 18px;
  opacity: 0.6;
}
.tool-bar-icon:hover {
  opacity: 1;
}

.transcription-original {
  position: fixed;
  top: calc(var(--header-height) + var(--action-height));
  left: 0;
  width: 50%;
  height: calc(100% - var(--header-height) - var(--action-height));
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #1e1f22;
  cursor: default;
}
.transcription-original:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.original-headline {
  position: fixed;
  top: var(--header-height);
  height: var(--action-height);
  left: 0;
  width: 50%;
  z-index: -1;
  background-color: #1e1f22;
  padding: 32px;
}
.transcription-add {
  position: fixed;
  top: calc(var(--header-height) + var(--action-height));
  right: 0;
  width: 50%;
  height: calc(100% - var(--header-height) - var(--action-height));
  overflow-y: scroll;
  overflow-x: hidden;
}
.transcription-add:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.btn-width {
  min-width: 120px;
}
.action-btns-div {
  position: fixed;
  top: var(--header-height);
  height: var(--action-height);
  right: 0;
  width: 50%;
  z-index: 1;
}
.action-btns-div-border {
  border-bottom: 1px dashed #5e5e5e;
}
.add-language-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% - 110px);
  display: flex;
  padding: 32px;
  overflow-x: auto;
  overflow-y: hidden;
}
.add-language-div::-webkit-scrollbar {
  height: 4px;
}
.add-language-div:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.add-language-tooltip {
  position: absolute;
  top: 42px;
  right: 114px;
  color: white;
  font-size: 10px;
  background-color: #2c2d32;
  border-radius: 2px;
  padding: 3px 6px;
  pointer-events: none;
}
.shake-tooltip {
  animation: shake 1s;
}
@keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  40% {
    transform: translate(2px, 0px) rotate(0deg);
  }
  60% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  80% {
    transform: translate(2px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
/* Safari and Chrome */
@-webkit-keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  40% {
    transform: translate(2px, 0px) rotate(0deg);
  }
  60% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  80% {
    transform: translate(2px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
.arrow-right {
  position: absolute;
  right: -8px;
  top: 4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 10px;
  border-color: transparent transparent transparent #2c2d32;
}

.action-btn-block {
  position: relative;

  &__dropdown {
    position: absolute;
    top: 80px;
    right: 30px;
    z-index: 1;
  }
}
.action-btn {
  position: absolute;
  top: 34px;
  border: 1px solid var(--primary-color);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  padding: 5px;
  z-index: 1;
  &--disabled {
    border: 1px solid white !important;
    opacity: 0.2;
  }
  &.add-btn {
    right: 68px;
  }
  &.more-btn {
    right: 24px;
  }
}

.dropdown-list {
  max-height: 400px;
  overflow: auto;
  background: #2c2d32 !important;
}
.dropdown-list::-webkit-scrollbar {
  width: 4px;
}
.dropdown-list:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.v-list {
  background: #2c2d32 !important;
  color: white;
}
.dropdown-list-item:hover {
  background-color: #52545d !important;
}
.list-btn {
  display: flex;
  height: 40px;
  padding: 0 6px 10px 6px;
  margin-right: 12px;
  border-bottom: 4px solid transparent;
  color: white;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.5;
}
.list-btn-icon {
  margin-top: 2px;
  margin-left: 4px;
}
.target-btn {
  padding: 0 6px 8px 6px;
  opacity: 1;
  color: #cccccc;
  border-bottom: 4px solid #4689f4;
}
.delete-popup {
  position: relative;
  height: 52px;
  overflow: hidden;
}
.delete-popup-div {
  position: relative;
  display: flex;
  height: 44px;
  background-color: #e03535;
  border-radius: 2px;
  padding: 0 6px;
}
.delete-popup-btn {
  position: relative;
  color: white;
  opacity: 0.8;
  padding: 4px;
}
.delete-popup-btn:hover {
  opacity: 1;
}
.delete-popup-text {
  position: relative;
  width: 100%;
  z-index: 1;
  bottom: 20px;
  left: 0;
  font-size: 10px;
  text-align: center;
}
.delete-popup-line {
  border-right: 1px solid #bc2626;
  border-left: 1px solid #ff5656;
  height: 22px;
  width: 0;
  margin-top: 10px;
}
.arrow-down {
  position: absolute;
  bottom: -6px;
  right: 2px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 8px 0 8px;
  border-color: #e03535 transparent transparent transparent;
}
.progress-linear-l {
  position: fixed;
  top: calc(var(--header-height) + var(--action-height));
  left: 0;
  width: 50%;
  height: 0;
  margin: 0;
}
.progress-linear-r {
  position: fixed;
  top: calc(var(--header-height) + var(--action-height));
  right: 0;
  width: 50%;
  height: 0;
  margin: 0;
}
.title-wrap {
  border-bottom: 1px dashed #5e5e5e;
  padding: 16px 32px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #0c0c0f;
  text-align: left;
}
.original-title-wrap {
  background-color: #1e1f22;
}
.step-wrap {
  border-bottom: 1px dashed #5e5e5e;
  padding: 16px 30px 16px 110px;
  text-align: left;
}
.step-num {
  position: absolute;
  left: 32px;
  font-size: 11px;
  color: #949494;
  font-weight: 600;
  padding-top: 6px;
}
.textarea-wrap {
  position: relative;
  margin: 0;
  width: calc(100% - 110px);
  width: 100%;
  margin-bottom: 12px;
}
.original-sentence {
  position: relative;
  text-align: left;
  width: 100%;
  color: #8d909f;
  font-size: 20px;
  line-height: 28px;
}
.original-sentence-highlight {
  color: white;
  background-color: rgba(100, 100, 100, 0.65);
}
.sentence-edit {
  pointer-events: auto;
  padding: 0;
  width: 100%;
  resize: none;
  color: #8d909f;
  font-size: 20px;
  line-height: 28px;
  overflow-y: auto;
}
.sentence-edit::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.4; /* Firefox */
}
.sentence-edit:focus {
  outline: none !important;
}
.sentence-edit-highlight {
  color: white;
  background-color: #4689f4 !important;
  caret-color: black;
}
.sentence-edit-highlight:focus {
  outline: none !important;
}
.sentence-edit-highlight::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.4; /* Firefox */
}
.sentence-empty {
  color: #ffffff !important;
  background-color: #454546;
  border-bottom: #e03535 2px solid !important;
}
.v-menu__content {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/* Titles text */
.step-title {
  color: #ffffff;
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 6px;
}
.title-text {
  text-align: left;
  color: #ffffff;
  font-size: 32px;
  line-height: 36px;
}
.untitled-text {
  display: inline-block;
  margin-right: 24px;
  cursor: default;
}
.untitled-step-text {
  cursor: default;
}
.target-title-wrap {
  padding-bottom: 10px;
}
.show-btn-inline {
  display: flex;
}
.right-to-left {
  text-align: right;
  direction: rtl;
}
</style>
