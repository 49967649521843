<template>
  <section
    class="PlayerMavenQa"
    :class="{
      PlayerMavenQa__show: show,
    }"
  >
    <v-toolbar dark color="#1D1E21" height="80px" class="elevation-0">
      <button @click="$emit('closePlayerMavenQa')">
        <font-awesome-icon :icon="['fas', 'angle-left']" style="height: 40px; width: 40px" />
      </button>
      <div class="PlayerMavenQa__title">
        {{ workflow.title }}
      </div>
    </v-toolbar>

    <div class="PlayerMavenQa__results">
      <qa
        key="fullscreenQA"
        v-if="show"
        :workflowId="workflow.id"
        is-full-screen
        @addon-click="$emit('closePlayerMavenQa')"
      />
    </div>
  </section>
</template>

<script>
import Qa from "../Q&A/Q&A.vue";

export default {
  name: "PlayerMavenQa",
  props: {
    show: {
      type: Boolean,
    },
    workflow: {
      type: Object,
    },
  },
  components: {
    Qa,
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.PlayerMavenQa {
  z-index: -2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #121314;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;

  &__title {
    margin-left: 16px;
    font-size: 20px;
    font-weight: 500;
  }
  &__show {
    z-index: 12;
    opacity: 1;
    transition: 0.3s;
    pointer-events: auto;
  }

  &__results {
    height: calc(100vh - 80px);
    padding-top: 48px;
  }
}
</style>
