<template>
  <div class="auth-wrapper">
    <!-- ////// Desktop and Mobile version ///// -->
    <div v-if="!showIframeLogin" class="all-content">
      <d-layout row wrap class="justify-center" style="position: relative">
        <d-flex class="d-flex text-xs-left justify-center align-center xs12 md6 lg5 xl4">
          <v-card flat color="transparent" class="elevation-0 headline-card">
            <div class="headline-box">
              <p class="login-headline mb-0" v-html="$t('auth.authTitle')"></p>
              <span class="gradient-text">{{ $t("auth.authTitleGradientColorText") }}</span>
            </div>
          </v-card>
        </d-flex>
        <!-- sso-only -->
        <d-flex v-if="showExitMessageOnAuthPage" class="xs12 md6 lg5 xl4">
          <v-card flat color="white" class="auth-box-lg">
            <div class="consent auth-box-background">
              <div v-if="isLoggingInSso" class="logging-in-sso">
                <div class="fullscreen-center">
                  <v-progress-circular
                    size="68"
                    width="3"
                    color="dBlue"
                    indeterminate
                    class="mb-3"
                  ></v-progress-circular>
                  <div class="log-in-subtitle">
                    {{ $t("auth.loggingInWithSSO") }}
                  </div>
                </div>
              </div>
              <div v-if="isLoadingSso" class="logging-in-sso">
                <div class="fullscreen-center">
                  <v-progress-circular
                    size="68"
                    width="3"
                    color="dBlue"
                    indeterminate
                    class="mb-3"
                  ></v-progress-circular>
                </div>
              </div>
              <div class="log-in-div">
                <img class="deephow-logo" src="@/img/deephow-logo.png" alt="" />
                <p class="white--text">Logged out successfully</p>
                <div class="consent__desc">Please log out from all DeepHow apps for the changes to make effect</div>
              </div>
              <div class="btn-div">
                <v-btn
                  depressed
                  round
                  block
                  class="body-2 text-capitalize white--text btn-text log-in-btn"
                  color="#4689f4"
                  @click="clickOk"
                  >Ok</v-btn
                >
              </div>
            </div>
          </v-card>
        </d-flex>
        <d-flex v-else-if="loginType == 'sso-only'" class="xs12 md6 lg5 xl4">
          <v-card v-if="showform == 'email-or-id'" flat class="auth-box-lg">
            <div class="auth-box-background">
              <div v-if="isLoggingInSso" class="logging-in-sso">
                <div class="fullscreen-center">
                  <v-progress-circular
                    size="68"
                    width="3"
                    color="dBlue"
                    indeterminate
                    class="mb-3"
                  ></v-progress-circular>
                  <div class="log-in-subtitle">
                    {{ $t("auth.loggingInWithSSO") }}
                  </div>
                </div>
              </div>
              <div v-if="isLoadingSso" class="logging-in-sso">
                <div class="fullscreen-center">
                  <v-progress-circular
                    size="68"
                    width="3"
                    color="dBlue"
                    indeterminate
                    class="mb-3"
                  ></v-progress-circular>
                </div>
              </div>
              <div class="log-in-div text-xs-left">
                <p class="log-in-title">{{ $t("auth.welcomeToDeepHow") }}</p>
                <p class="log-in-subtitle">{{ $t("auth.logInSubtitle") }}</p>
                <div class="ssoLoginButtons">
                  <button @click="signInWithSSO" class="white--text">
                    <font-awesome-icon :icon="['fa', 'key']" class="ssoLoginButtons-icon" />{{
                      $t("auth.logInWithSSO")
                    }}
                  </button>
                </div>
              </div>
            </div>
          </v-card>
        </d-flex>

        <d-flex
          v-else-if="loginType == 'email-only' || loginType == 'email-and-sso' || loginType == 'email-generic-sso'"
          class="xs12 md6 lg5 xl4"
        >
          <!-- email-only -->
          <v-card v-if="loginType == 'email-only' && showform == 'email-or-id'" flat class="auth-box-lg">
            <div class="auth-box-background">
              <div class="log-in-div text-xs-left">
                <p class="log-in-title">{{ $t("auth.welcomeToDeepHow") }}</p>
                <p class="log-in-subtitle">{{ $t("auth.logInSubtitle") }}</p>
                <v-form v-model="formValid" autocomplete="off">
                  <v-text-field
                    ref="emailOrIdField"
                    placeholder=" "
                    dark
                    :label="$t('auth.email')"
                    v-model="email"
                    class="input-field pb-2"
                    autocomplete="chrome-off"
                    :rules="[rules.required, rules.noWhiteSpace]"
                    @keydown.native="noSpace($event)"
                    @keydown.enter.prevent="nextByKeydownEnter($event)"
                  ></v-text-field>
                  <p class="text-xs-right forgot-password" @click="showform = 'reset-password'">
                    {{ $t("auth.forgotPassword") }}?
                  </p>
                </v-form>
              </div>
              <div class="btn-div">
                <button
                  @click="logInEmailOrId"
                  :disabled="!formValid"
                  class="dh-btn btn-primary log-in-btn justify-center"
                >
                  {{ $t("auth.logIn") }}
                </button>
              </div>
            </div>
          </v-card>

          <!-- email-and-sso -->
          <v-card v-if="loginType == 'email-and-sso' && showform == 'email-or-id'" flat class="auth-box-lg">
            <div class="auth-box-background">
              <div v-if="isLoggingInSso" class="logging-in-sso">
                <div class="fullscreen-center">
                  <v-progress-circular
                    size="68"
                    width="3"
                    color="dBlue"
                    indeterminate
                    class="mb-3"
                  ></v-progress-circular>
                  <div class="log-in-subtitle">
                    {{ $t("auth.loggingInWithSSO") }}
                  </div>
                </div>
              </div>
              <div v-if="isLoadingSso" class="logging-in-sso">
                <div class="fullscreen-center">
                  <v-progress-circular
                    size="68"
                    width="3"
                    color="dBlue"
                    indeterminate
                    class="mb-3"
                  ></v-progress-circular>
                </div>
              </div>
              <div class="log-in-div text-xs-left">
                <p class="log-in-title">{{ $t("auth.welcomeToDeepHow") }}</p>
                <p class="log-in-subtitle">{{ $t("auth.logInSubtitle") }}</p>
                <div class="ssoLoginButtons">
                  <button @click="signInWithSSO" class="white--text">
                    <font-awesome-icon :icon="['fa', 'key']" class="ssoLoginButtons-icon" />{{
                      $t("auth.logInWithSSO")
                    }}
                  </button>
                </div>
                <div class="or-div">
                  <div class="or-line"></div>
                  <div class="or-text">{{ $t("explorer.or") }}</div>
                </div>
                <v-form v-model="formValid" autocomplete="off">
                  <v-text-field
                    ref="emailOrIdField"
                    placeholder=" "
                    dark
                    :label="isSsoLoginOnly ? $t('all.employeeId') : $t('auth.emailOrId')"
                    v-model="email"
                    class="input-field pb-2"
                    autocomplete="chrome-off"
                    :rules="[rules.required, rules.noWhiteSpace]"
                    @keydown.native="noSpace($event)"
                    @keydown.enter.prevent="nextByKeydownEnter($event)"
                  ></v-text-field>
                  <p class="text-xs-right forgot-password" @click="showform = 'reset-password'">
                    {{ $t("auth.forgotPassword") }}?
                  </p>
                </v-form>
              </div>
              <div class="btn-div">
                <button
                  @click="logInEmailOrId"
                  :disabled="!formValid"
                  class="dh-btn btn-primary log-in-btn justify-center"
                >
                  {{ $t("auth.logIn") }}
                </button>
              </div>
            </div>
          </v-card>

          <!-- email-generic-sso -->
          <v-card v-if="loginType == 'email-generic-sso' && showform == 'email-or-id'" flat class="auth-box-lg">
            <div class="auth-box-background">
              <div v-if="isLoggingInSso" class="logging-in-sso">
                <div class="fullscreen-center">
                  <v-progress-circular
                    size="68"
                    width="3"
                    color="dBlue"
                    indeterminate
                    class="mb-3"
                  ></v-progress-circular>
                  <div class="log-in-subtitle">
                    {{ $t("auth.loggingInWithSSO") }}
                  </div>
                </div>
              </div>
              <div v-if="isLoadingSso" class="logging-in-sso">
                <div class="fullscreen-center">
                  <v-progress-circular
                    size="68"
                    width="3"
                    color="dBlue"
                    indeterminate
                    class="mb-3"
                  ></v-progress-circular>
                </div>
              </div>
              <div class="log-in-div text-xs-left">
                <p class="log-in-title">{{ $t("auth.welcomeToDeepHow") }}</p>
                <p class="log-in-subtitle">{{ $t("auth.logInSubtitle") }}</p>
                <div class="ssoLoginButtons">
                  <button @click="signInWithGoogleSSO" class="white--text">
                    <img src="../img/sso-google.png" /> {{ $t("auth.continueWithGoogle") }}
                  </button>
                  <button @click="signInWithMicrosoftSSO" class="white--text">
                    <img src="../img/sso-microsoft.svg" /> {{ $t("auth.continueWithMicrosoft") }}
                  </button>
                </div>
                <div class="or-div">
                  <div class="or-line"></div>
                  <div class="or-text">{{ $t("explorer.or") }}</div>
                </div>
                <v-form v-model="formValid" autocomplete="off">
                  <v-text-field
                    ref="emailOrIdField"
                    dark
                    :label="$t('auth.emailOrId')"
                    v-model="email"
                    class="input-field pb-2"
                    autocomplete="chrome-off"
                    :rules="[rules.required, rules.noWhiteSpace]"
                    placeholder=" "
                    @keydown.native="noSpace($event)"
                    @keydown.enter.prevent="nextByKeydownEnter($event)"
                  ></v-text-field>
                  <p class="text-xs-right forgot-password" @click="showform = 'reset-password'">
                    {{ $t("auth.forgotPassword") }}?
                  </p>
                </v-form>
              </div>
              <div class="btn-div">
                <button
                  @click="logInEmailOrId"
                  :disabled="!formValid"
                  class="dh-btn btn-primary log-in-btn justify-center"
                >
                  {{ $t("auth.logIn") }}
                </button>
              </div>
            </div>
          </v-card>

          <!-- email password -->
          <v-card v-if="showform == 'password'" flat class="auth-box-lg">
            <div class="auth-box-background">
              <div class="log-in-div text-xs-left">
                <p class="log-in-title">
                  <button @click="showform = 'email-or-id'" class="back-btn">
                    <icon-base height="20" width="20">
                      <d-icon-arrow-back />
                    </icon-base>
                  </button>
                  {{ $t("auth.hello") }} {{ getEmailName(email) }}!
                </p>
                <p class="log-in-subtitle">{{ $t("auth.passwordSubtitle") }}</p>
                <v-form v-model="formValid" autocomplete="off">
                  <v-text-field
                    ref="passwordField"
                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    autocomplete="new-password"
                    :rules="[rules.required]"
                    placeholder=" "
                    dark
                    :label="$t('auth.password')"
                    v-model="password"
                    class="input-field pb-2"
                    @keydown.enter="nextByKeydownEnter($event)"
                  ></v-text-field>
                  <p
                    v-if="isLoginWithEmailAccount"
                    class="text-xs-right forgot-password"
                    @click="showform = 'reset-password'"
                  >
                    {{ $t("auth.forgotPassword") }}?
                  </p>
                </v-form>
              </div>
              <div class="btn-div">
                <button
                  @click="signIn(email, password)"
                  :disabled="!formValid"
                  class="dh-btn btn-primary log-in-btn justify-center"
                >
                  {{ $t("auth.logIn") }}
                </button>
              </div>
            </div>
          </v-card>

          <v-card v-if="showform == 'reset-password' && !sendResetSuccess" flat class="auth-box-lg">
            <div class="auth-box-background">
              <div class="log-in-div text-xs-left">
                <p class="log-in-title">
                  <button @click="showform = 'email-or-id'" class="back-btn">
                    <icon-base height="20" width="20">
                      <d-icon-arrow-back />
                    </icon-base>
                  </button>
                  {{ $t("auth.forgotPassword") }}
                </p>
                <p class="log-in-subtitle">
                  {{ $t("auth.forgotPasswordSubtitle") }}
                </p>
                <v-form v-model="formValid" autocomplete="off">
                  <v-text-field
                    v-model="email"
                    :label="$t('auth.email')"
                    class="input-field"
                    autocomplete="chrome-off"
                    :rules="[rules.required, rules.noWhiteSpace, rules.emailAllowed]"
                    placeholder=" "
                    @keydown.enter="nextByKeydownEnter($event)"
                    @keydown.native="noSpace($event)"
                    dark
                  ></v-text-field>
                </v-form>
              </div>
              <div class="btn-div">
                <button
                  @click="handleRecoverPassword"
                  :disabled="!formValid"
                  class="dh-btn btn-primary log-in-btn justify-center mt-3"
                >
                  {{ $t("auth.resetPassword") }}
                </button>
              </div>
            </div>
          </v-card>

          <v-card v-if="showform == 'reset-password' && sendResetSuccess" flat class="auth-box-lg">
            <div class="auth-box-background">
              <div class="log-in-div text-xs-left">
                <p class="log-in-title">
                  <button @click="(sendResetSuccess = false), (showform = 'email-or-id')" class="back-btn">
                    <icon-base height="20" width="20">
                      <d-icon-arrow-back />
                    </icon-base></button
                  >{{ $t("all.success") }}!
                </p>
                <p class="log-in-subtitle">
                  {{ $t("auth.successMsg") }}
                </p>
              </div>
            </div>
          </v-card>
        </d-flex>
      </d-layout>

      <!-- copy right & privacy policy -->
      <div class="copy-right">
        <span v-if="$backendType != 'ali'">
          <span @click="popupContent = 'privacy-policy-en'" class="text-hover">
            {{ $t("all.privacyPolicy") }}
          </span>
          <span> &copy; {{ new Date().getFullYear() }} - DeepHow Corp. {{ $t("auth.allRightReserved") }}</span>
        </span>

        <span v-else>
          <a target="_blank" href="http://beian.miit.gov.cn/">
            <span class="white--text">沪ICP备2020037560号</span>
          </a>
          <span class="white-dot"></span>
          <span> &copy; {{ new Date().getFullYear() }} - DeepHow Corp. {{ $t("auth.allRightReserved") }}</span>
          <span class="white-dot"></span>
          <span @click="popupContent = 'privacy-policy-zh'" class="text-hover-zh">
            {{ $t("all.privacyPolicy") }}
          </span>
          <span class="white-dot"></span>
          <span @click="popupContent = 'user-agreement-zh'" class="text-hover-zh">
            {{ $t("all.userAgreement") }}
          </span>
        </span>
      </div>
    </div>

    <!-- ////// iFrame version ////////-->
    <!-- for ABI embedded videos on Acadia -->
    <!-- 'email-and-sso' is the loginType for https://abi.deephow.ai/-->

    <div v-if="showIframeLogin" class="auth-iframe-login">
      <!-- Loading -->
      <div v-if="isLoggingInSso || isLoadingSso" class="logging-in-sso">
        <div class="fullscreen-center">
          <v-progress-circular size="48" width="3" color="dBlue" indeterminate></v-progress-circular>
          <div v-if="isLoggingInSso" class="iframe-title-text mt-3">
            {{ $t("auth.loggingInWithSSO") }}
          </div>
        </div>
      </div>
      <!-- Enter email or id / click sso -->
      <div v-if="showform == 'email-or-id'" class="iframe-login-div">
        <deephow-logo-light-bg width="160" height="34" />
        <div v-html="$t('auth.loginToYourAccount')" class="iframe-title-text"></div>
        <d-button depressed round block outline class="body-2 btn-text mt-4" color="#E9E9E9" @click="signInWithSSO()">
          <span class="iframe-sso-btn">{{ $t("auth.logInWithSSO") }}</span>
        </d-button>
        <div class="iframe-or-div">
          <div class="or-line"></div>
          <div class="or-text">{{ $t("explorer.or") }}</div>
        </div>
        <div class="iframe-bottom-div">
          <div class="iframe-email-box">
            <v-form v-model="formValid" autocomplete="off">
              <v-text-field
                ref="emailOrIdField"
                single-line
                full-width
                hide-details
                v-model="email"
                class="input-field iframe-input-field"
                autocomplete="chrome-off"
                :rules="[rules.required, rules.noWhiteSpace]"
                :placeholder="$t('auth.emailOrId')"
                @keydown.native="noSpace($event)"
                @keydown.enter="nextByKeydownEnter($event)"
              ></v-text-field>
              <div v-if="email" class="iframe-input-lable">{{ $t("auth.emailOrId") }}</div>
            </v-form>
          </div>
          <div class="iframe-login-btn">
            <d-button
              :disabled="!formValid"
              depressed
              round
              block
              class="body-2 text-capitalize white--text btn-text ma-0"
              color="#4689f4"
              light
              @click="logInEmailOrId()"
              >{{ $t("auth.logIn") }}</d-button
            >
          </div>
        </div>
      </div>
      <!-- Enter password -->
      <div v-if="showform == 'password'" class="iframe-login-div">
        <div class="iframe-title-text text-no-wrap text-truncate">
          <b
            >{{ $t("auth.hello") }} <span class="text-capitalize">{{ getEmailName(email) }}!</span></b
          >
        </div>
        <div class="iframe-title-text">
          {{ $t("auth.pleaseInputYourPassword") }}
        </div>
        <div class="iframe-input-box mt-4">
          <v-form v-model="formValid" autocomplete="off">
            <v-text-field
              ref="passwordField"
              v-model="password"
              single-line
              full-width
              hide-details
              class="input-field iframe-input-field ma-0"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              autocomplete="new-password"
              :rules="[rules.required]"
              :placeholder="$t('auth.password') + ' *'"
              @keydown.enter="nextByKeydownEnter($event)"
            ></v-text-field>
            <div class="iframe-forgot-password" @click="showform = 'reset-password'">
              {{ $t("auth.forgotPassword") }}?
            </div>
            <div v-if="password" class="iframe-input-lable">{{ $t("auth.password") }} *</div>
          </v-form>
        </div>
        <div class="iframe-bottom-div">
          <d-button
            :disabled="!formValid"
            depressed
            round
            block
            class="body-2 text-capitalize white--text btn-text ma-0"
            color="#4689f4"
            light
            @click="signIn(email, password)"
            >{{ $t("auth.logIn") }}</d-button
          >
        </div>
        <button @click="showform = 'email-or-id'" class="iframe-back-btn">
          <icon-base height="22" width="22" color="#52545D">
            <d-icon-arrow-back />
          </icon-base>
        </button>
      </div>
      <!-- Reset password -->
      <div v-if="showform == 'reset-password'" class="iframe-login-div">
        <!-- request email -->
        <div v-if="!sendResetSuccess">
          <div class="iframe-text-password">
            <b>{{ $t("auth.forgotPassword") }}</b>
            <br />{{ $t("auth.forgotPasswordSubtitle") }}
          </div>
          <div class="iframe-input-box mt-4">
            <v-form v-model="formValid" autocomplete="off">
              <v-text-field
                ref="emailOrIdField"
                single-line
                full-width
                hide-details
                v-model="email"
                class="input-field iframe-input-field"
                autocomplete="chrome-off"
                :rules="[rules.required, rules.noWhiteSpace]"
                :placeholder="$t('auth.emailOrId')"
                @keydown.native="noSpace($event)"
                @keydown.enter="nextByKeydownEnter($event)"
              ></v-text-field>
              <div v-if="email" class="iframe-input-lable">{{ $t("auth.emailOrId") }}</div>
            </v-form>
          </div>
          <div class="iframe-bottom-div">
            <d-button
              :disabled="!formValid"
              depressed
              round
              block
              class="body-2 text-capitalize white--text btn-text ma-0"
              color="#4689f4"
              light
              @click="handleRecoverPassword()"
              >{{ $t("auth.resetPassword") }}</d-button
            >
          </div>
          <button @click="showform = 'password'" class="iframe-back-btn">
            <icon-base height="22" width="22" color="#52545D">
              <d-icon-arrow-back />
            </icon-base>
          </button>
        </div>
        <!-- send email success -->
        <div v-else>
          <div class="success-icon mt-2 mb-1">
            <icon-base height="48" width="48">
              <d-icon-success />
            </icon-base>
          </div>
          <div class="iframe-text-password">
            <b class="dBlue--text">{{ $t("all.success") }}!</b>
            <br />
            <span v-html="$t('auth.successMsg')"></span>
          </div>
          <div class="iframe-bottom-div">
            <d-button
              depressed
              round
              block
              class="body-2 text-capitalize white--text btn-text ma-0"
              color="#4689f4"
              light
              @click="(sendResetSuccess = false), (showform = 'email-or-id')"
            >
              {{ $t("auth.backToLogin") }}
            </d-button>
          </div>
        </div>
      </div>
    </div>

    <!-- privacy-policy-popup -->
    <user-privacy-policy-popup
      v-if="popupContent"
      :popupContent="popupContent"
      :isMobileDevice="isMobileDevice"
      :isTabletDevice="isTabletDevice"
      @close-policy="popupContent = ''"
      @close-policy-mobile="popupContent = ''"
    />

    <!-- alert -->
    <d-alert v-model="sendResetFailed" type="alert" :message="$t('auth.alertResetFailed')"></d-alert>
    <d-alert v-model="showSsoLoginOnlyAlert" type="alert" :message="$t('auth.useSsoLogin')"></d-alert>
  </div>
</template>

<script>
import firebase from "firebase";
import MixinDB from "./MixinDB.vue";
import MixinUser from "./MixinUser.vue";
import MixinAnalytics from "@/components/MixinAnalytics.vue";
import IconBase from "./IconBase.vue";
import DeephowLogo from "./icons/logo/DeephowLogo.vue";
import DIconSuccess from "./icons/colored/DIconSuccess.vue";
import DIconArrowBack from "./icons/DIconArrowBack.vue";
import DIconUserLine from "./icons/DIconUserLine.vue";
import DAlert from "./ui_components/DAlert.vue";
import UserPrivacyPolicyPopup from "@/components/DUser/UserPrivacyPolicyPopup.vue";
import DeephowLogoLightBg from "@/components/icons/logo/DeephowLogoLightBg.vue";
import { redriectToVendorDomain, getEmailAccountOfRedirection } from "../auth/sso-subdomain.js";
import { getCheckIdpByEmail, getCheckIdpByHostname } from "@/server/sso-server.js";
import { isEnableMasterPassword } from "@/js/employeeid-login/employeeid-login-service.js";
import {
  LOGIN_ACCOUNT_TYPE,
  HAS_ALREADY_SIGN_IN_KEY,
  setSignInLocalStorage,
  removeSignInLocalStorage,
  removeLogoutLocalStorage,
} from "@/js/login/login.js";
import {
  setSharedAccountInLocalStorage,
  removeSharedAccountFromLocalStorage,
  isSharedAccount,
} from "@/js/user/share-account.js";
import { setEmployeeIdAccountInLocalStorage, removeEmployeeIdAccountFromLocalStorage } from "@/js/user/employee-id.js";
import { BROWSER_TAB_ID_KEY, BROWSER_TAB_KEY, LOGOUT_NOTIFY_KEY, removeIdleLocalStorage } from "@/js/idle/idle.js";
import { checkEmailCharacters } from "@/js/character-validator/character-validator.js";
import { loggingError } from "@/server/error-log-server.js";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Auth",
  props: {
    isMobileDevice: Boolean,
    isTabletDevice: Boolean,
  },
  data: function () {
    return {
      isTryingToLogin: null,
      loginAccountType: null,
      email: "",
      password: "",
      showPassword: false,
      formValid: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noWhiteSpace: (value) => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        emailAllowed: () => !this.emailNotAllowed || this.$t("rules.invalidEmail"),
      },
      sendResetSuccess: false,
      sendResetFailed: false,
      showform: "email-or-id", // "email-or-id", "password", "reset-password"
      emailOnlyList: ["deephow.net", "www.deephow.net"],
      emailAndGenericSSOList: ["local.deephow.ai", "dev.deephow.ai", "dev1.deephow.ai", "deephow.ai", "localhost"],
      ssoOnlyList: [],
      loginType: "email-and-sso", // 'sso-only', 'email-only', 'email-and-sso', 'email-generic-sso'
      isSsoLoginOnly: false,
      showSsoLoginOnlyAlert: false,
      isLoggingInSso: false,
      isLoadingSso: false,
      isUsingIframe: false,
      showIframeLogin: false,
      popupContent: "",
      pdfUrl: this.$download.privacyPolicyPDF,
      showExitMessageOnAuthPage: false,
    };
  },
  computed: {
    ...mapGetters("permission", ["getter_dh_login_enabled"]),
    isLoginWithEmailAccount() {
      return !this.isSsoLoginOnly;
    },
    emailNotAllowed() {
      return checkEmailCharacters(this.email);
    },
  },
  mixins: [MixinDB, MixinUser, MixinAnalytics],
  components: {
    IconBase,
    DeephowLogo,
    DIconSuccess,
    DIconArrowBack,
    DIconUserLine,
    DAlert,
    UserPrivacyPolicyPopup,
    DeephowLogoLightBg,
  },
  created() {
    this.checkIframeLogin();
    let self = this;
    this.auth().onAuthStateChanged((user) => {
      if (user) {
        // self.password = ""; // erase password after login
      } else {
      }
    });
    this.setLoginType();
    this.autoSignInWithSSO();
    this.tryBackfillEmailAccountOfRedirection();

    /** reset and clear idle localstorage */
    removeIdleLocalStorage(BROWSER_TAB_ID_KEY);
    removeIdleLocalStorage(BROWSER_TAB_KEY);
    removeIdleLocalStorage(LOGOUT_NOTIFY_KEY);
  },
  mounted() {
    if (localStorage.getItem("showExitMessageOnAuthPage")) {
      this.showExitMessageOnAuthPage = true;
    }
    /** China version cannot auto reload all tabs after sign in or logout */
    if (this.$backendType === "ali") {
      // localstorage event
      window.addEventListener("storage", this.onStorageEvent);
    }

    this.$nextTick(() => this.$refs.emailOrIdField?.focus());
  },
  beforeDestroy() {
    if (this.$backendType === "ali") {
      // when user leave Auth.vue, that's mean user has signed in
      setSignInLocalStorage();

      // remove localstorage event
      window.removeEventListener("storage", this.onStorageEvent);
    }
  },
  destroyed() {
    removeSignInLocalStorage();
    removeLogoutLocalStorage();
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    ...mapActions("permission", ["getOrgPermissionsSettings"]),
    async setLoginType() {
      // All Login Types(desktop and mobile): 'sso-only', 'email-only', 'email-and-sso', 'email-generic-sso'
      // default: 'email-and-sso'
      if (this.emailOnlyList.includes(window.location.hostname)) {
        this.loginType = "email-only";
      }
      if (this.ssoOnlyList.includes(window.location.hostname)) {
        this.loginType = "sso-only";
      }
      if (this.emailAndGenericSSOList.includes(window.location.hostname)) {
        this.loginType = "email-generic-sso";
      }
      // if isSsoLoginOnly == true, Email login is disabled.
      const hostname = window.location.hostname;
      const data = await getCheckIdpByHostname(hostname);
      this.isSsoLoginOnly = data.ssoLoginOnly || false;
    },
    checkIframeLogin() {
      if (window.self != window.top) {
        this.isUsingIframe = true;
      } else {
        return;
      }
      const hostname = window.location.hostname;
      const subdomain = hostname.slice(0, hostname.indexOf("."));
      if (subdomain === "abi" || subdomain.includes("dev") || hostname == "localhost") {
        // only show iframe log in screen for ABI (https://abi.deephow.ai/) and test
        this.showIframeLogin = true;
      }
    },
    tryBackfillEmailAccountOfRedirection() {
      const restoreEmailAccount = getEmailAccountOfRedirection();
      if (restoreEmailAccount) {
        this.email = restoreEmailAccount;
      }
    },
    autoSignInWithSSO() {
      if (this.$backendType == "ali") {
        return console.log("Not implment ...");
      }
      // for ABI embedded videos
      if (
        this.isUsingIframe &&
        !this.emailOnlyList.includes(window.location.hostname) &&
        this.$route.query.redirect &&
        !firebase.auth().currentUser
      ) {
        this.signInWithSSO();
      }
    },
    getEmailName(emailAdd) {
      let name = emailAdd.slice(0, emailAdd.indexOf("@"));
      name = name.substring(name.length - 7) === ".shared" ? name.substring(0, name.length - 7) : name;
      return name;
    },
    noSpace($event) {
      if ($event.key === " ") {
        $event.preventDefault();
      }
    },
    nextByKeydownEnter($event) {
      $event.preventDefault();
      if (this.showform == "email-or-id" && this.formValid) {
        this.logInEmailOrId();
        return;
      }
      if (this.showform == "password" && this.formValid) {
        this.signIn(this.email, this.password);
        return;
      }
      if (this.showform == "reset-password" && this.formValid) {
        this.handleRecoverPassword();
        return;
      }
    },
    showPasswordForm() {
      this.isTryingToLogin = false;

      this.showform = "password";
      this.$nextTick(() => this.$refs.passwordField?.focus());
    },
    /** Sign in with password
     * email with user's pwd
     * employeeId with master pwd
     */
    async signIn(account, password) {
      if (this.loginAccountType === LOGIN_ACCOUNT_TYPE.EMAIL) {
        localStorage.setItem("acceptedCookie", true);
        return this.signInWithEmail(account, password);
      }
      return this.sharedAccountOrEmployeeIdSignIn(account, password);
    },
    async setSharedAccountOrEmployeeIdLocalStorage(data) {
      const accountData = JSON.stringify({
        isSharedAccount: data.isSharedAccount,
        account: data.account,
        email: data.user.email,
        refreshToken: data.refreshToken,
      });
      data.isSharedAccount
        ? setSharedAccountInLocalStorage(accountData)
        : setEmployeeIdAccountInLocalStorage(accountData);
    },
    async sharedAccountOrEmployeeIdSignIn(account, password) {
      try {
        const { ok = false, data = {}, errorMessage } = await this.signInWithEmployeeId(account, password);
        this.isTryingToLogin = false;
        if (ok) {
          return this.setSharedAccountOrEmployeeIdLocalStorage(data);
        }
        return this.handleSharedAccountOrEmployeeIdSignInError(errorMessage);
      } catch (error) {
        this.isTryingToLogin = false;
        const message = this.$t("alert.unexpectedError");
        this.MUTATE_ALERT_INFO({ message, type: "alert" });
        loggingError(error);
      }
    },
    handleSharedAccountOrEmployeeIdSignInError(errorMessage) {
      const action = (messageKey) => {
        return this.MUTATE_ALERT_INFO({
          message: this.$t(messageKey),
          type: "alert",
        });
      };
      const errorActionsDictionary = {
        "password invalid": () => action("auth.wrongPassword"),
        "password is incorrect": () => action("auth.wrongPassword"),
        "not allowed": () => action("auth.alertDisableDHLogin"),
        "user is not found": () => action("auth.alertUserNotFound"),
        "Invalid employee ID": () => action("auth.alertInvalidId"),
        default: () => action("alert.unexpectedError"),
      };
      const errorMessageKey =
        Object.keys(errorActionsDictionary).find((key) => errorMessage.includes(key)) || "default";
      return errorActionsDictionary[errorMessageKey]();
    },
    async logInEmailOrId() {
      if (this.isTryingToLogin) {
        return;
      }

      this.isTryingToLogin = true;

      const self = this;
      const emailField = (this.email = this.email.trim());

      this.loginAccountType = !this.emailNotAllowed ? LOGIN_ACCOUNT_TYPE.EMAIL : LOGIN_ACCOUNT_TYPE.EMPLOYEEID;
      if (this.loginAccountType === LOGIN_ACCOUNT_TYPE.EMAIL) {
        // if ssoLoginOnly is true, email login is disabled, show alert
        // if it's main site (like deephow.ai) show alert then redirect to other Domain
        if (this.isSsoLoginOnly) {
          this.showSsoLoginOnlyAlert = true;
          this.isTryingToLogin = false;
          return;
        }
        const data = await getCheckIdpByEmail(emailField);
        const delayTime = data.ssoLoginOnly ? 2000 : 0;
        setTimeout(() => {
          const supportSsoLoginCB = (res) => {
            if (res.isNeedRedirectToOtherDomain) {
              const targetDomain = res.domain;
              redriectToVendorDomain(emailField, targetDomain);
            } else {
              self.showPasswordForm();
            }
          };
          const notYetSupportSsoLoginCB = () => {
            self.showPasswordForm();
          };
          self.checkSsoLoginStatus(emailField, supportSsoLoginCB, notYetSupportSsoLoginCB);
        }, delayTime);
      } else {
        const employeeId = emailField;
        const clientKey = this.generateClientKeyForEmployeeId({ employeeId });
        try {
          await this.getOrgPermissionsSettings({ clientKey });
        } catch (error) {
          this.isTryingToLogin = false;
          const message = this.$t("auth.alertEmployeeIdInvalid");
          this.MUTATE_ALERT_INFO({ message, type: "alert" });
          loggingError(error);
          return;
        }
        const { hasUnknownError, isEnabled } = await isEnableMasterPassword({ clientKey });
        const isSharedAccountUser = isSharedAccount({ account: employeeId });
        const handleDHLoginDisabled = () => {
          this.isTryingToLogin = false;
          const message = this.$t("auth.alertDisableDHLogin");
          return this.MUTATE_ALERT_INFO({ message, type: "alert" });
        };
        if (isSharedAccountUser) return this.showPasswordForm();
        if (!this.getter_dh_login_enabled) return handleDHLoginDisabled();
        if (hasUnknownError) return this.MUTATE_ALERT_INFO({ message: this.$t("auth.alertInvalidId"), type: "alert" });
        if (isEnabled) return this.showPasswordForm();
        return this.employeeIdLogin({ employeeId });
      }
    },
    /** Employee ID sign in without master password */
    async employeeIdLogin({ employeeId }) {
      const { ok, data = {}, errorMessage } = await this.signInWithEmployeeId(employeeId);
      this.isTryingToLogin = false;
      if (ok) {
        return this.setSharedAccountOrEmployeeIdLocalStorage(data);
      }
      return this.MUTATE_ALERT_INFO({ message: this.$t("auth.alertInvalidId"), type: "alert" });
    },
    handleRecoverPassword() {
      this.recoverPassword(this.email)
        .then(() => {
          this.sendResetSuccess = true;
        })
        .catch((err) => {
          this.sendResetFailed = true;
          loggingError(err);
        });
    },
    signInWithGoogle() {
      if (this.$backendType == "ali") {
        return console.log("Not implment ...");
      }

      var provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithRedirect(provider);
      firebase
        .auth()
        .then(function (result) {
          if (result.credential) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // ...
          }
          // The signed-in user info.
          var user = result.user;
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
          loggingError(error);
        });
    },
    /** methods: localStorage to reload all tabs*/
    onStorageEvent(event) {
      const updateKey = event.key;
      const value = event.newValue;
      if (updateKey === HAS_ALREADY_SIGN_IN_KEY) {
        if (this.$backendType === "ali" && value) {
          removeSignInLocalStorage();
          location.reload();
        }
      }
    },
    clickOk() {
      localStorage.removeItem("showExitMessageOnAuthPage");
      this.showExitMessageOnAuthPage = false;
    },
  },
};
</script>
<style scoped>
/* ///// Desktop and Mobile version ///// */
.auth-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.auth-wrapper:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.all-content {
  background-color: var(--dGrey1-color);
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding-bottom: 204px;
  overflow: hidden;
}
.log-in-content {
  width: 472px;
  margin: 0 auto;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.headline-card {
  max-width: 670px;
  margin: 0 auto;
}
.auth-box-lg {
  width: 100%;
  max-width: 550px;
  min-height: 660px;
  border-radius: 8px;
  opacity: 0.95;
  margin: 0 auto;
  margin-top: 64px;
  background-image: linear-gradient(90deg, #4689f3, #10b981);
  border-radius: 32px;
  padding: 2px;
}
.auth-box-background {
  height: 100%;
  min-height: 660px;
  border-radius: 30px;
  background: var(--dGrey2-color);
}
.consent.auth-box-background {
  display: flex;
  flex-direction: column;
}
.auth-box {
  position: relative;
  padding: 48px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
  max-width: 480px;
  margin: 0 auto;
  margin-top: 64px;
  min-height: 380px;
}
.log-in-div {
  position: relative;
  padding: 68px;
  padding-bottom: 0;
}
.consent .log-in-div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.reset-success-box {
  padding: 48px 28px;
}
.headline-box {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.gradient-text {
  background: linear-gradient(90deg, #4686f1 14.79%, #10b981 87.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -1.056px;
}
.login-headline {
  color: #ffffff;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -1.056px;
}
.white-line {
  height: 3px;
  width: 60px;
  background-color: #ffffff;
  margin: 32px 0;
}
.login-subheadline {
  color: #ffffff;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 32px;
  text-align: left;
}
.log-in-title {
  display: flex;
  color: #ffffff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.back-btn {
  display: flex;
  align-items: center;
  color: var(--brand-blue);
  margin-right: 16px;
}
.log-in-subtitle {
  color: var(--dLigthGrey400-color);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 40px;
}
.consent .log-in-subtitle {
  font-size: 16px;
}
.consent__desc {
  margin-top: 64px;
  font-size: 18px;
  color: #9397a6;
}
.log-in-subtitle-email {
  position: absolute;
  left: 0;
  top: 122px;
  padding: 0 48px;
  color: #52545d;
  opacity: 0.5;
  width: 100%;
  font-size: 16px;
  font-family: Roboto;
}
.success-btn {
  position: relative;
  margin-top: 48px;
}
.forgot-password {
  position: relative;
  z-index: 1;
  color: var(--brand-blue);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 40px;
}
.forgot-password:hover {
  cursor: pointer;
}
.btn-div {
  padding: 0px 68px;
  padding-bottom: 80px;
  width: 100%;
}
.log-in-btn {
  width: 100%;
}
.btn-text {
  text-transform: none !important;
}
.or-div {
  position: relative;
  width: 100%;
  left: 0;
  height: 20px;
  margin-bottom: 48px;
}
.or-line {
  border-top: 1px solid #3d3f45;
  position: absolute;
  top: 10px;
  width: 100%;
}
.or-text {
  opacity: 0.95;
  background: var(--dGrey2-color);
  position: relative;
  height: 20px;
  width: 80px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin: 0 auto;
}
.ssoLoginButtons {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.ssoLoginButtons button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid var(--dGrey4-color);
  background: var(--dGrey3-color);
  width: 100%;
  padding: 12px 0;
  margin: 8px 0;
}
.ssoLoginButtons button img,
.ssoLoginButtons-icon {
  width: 16px;
  margin-right: 16px;
  color: var(--brand-blue);
}
.sso-btn {
  position: relative;
  width: calc(100% - 96px);
  bottom: 0;
  left: 48px;
  margin: 0;
}
.success-icon {
  margin-top: 36px;
  margin-bottom: 16px;
}
.success-icon ::v-deep svg path {
  fill: #4689f4 !important;
  opacity: 1 !important;
}
/* for fixing Chrome auto-filling bug of Vuetify */
.input-field ::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.copy-right {
  color: var(--dGrey6-color);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  bottom: 110px;
  width: 100%;
  text-align: center;
}
.logging-in-sso {
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(40, 41, 46, 0.8);
  z-index: 2;
}
.white-dot {
  height: 4px;
  width: 4px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px 3px 10px;
}
.text-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}
.text-hover-zh {
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 959px) {
  .headline-card {
    margin-top: 64px;
  }
  .copy-right-us {
    text-align: center;
  }
}

/* ////// iFrame version //////// */
/* for ABI embedded videos on Acadia */
.auth-iframe-login {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  z-index: 99;
}
.iframe-title-text {
  position: relative;
  width: 100%;
  color: #97989d;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 0 32px;
}
.iframe-title-text ::v-deep b {
  color: #52545d;
  font-weight: 500;
}
.iframe-text-password {
  color: #97989d;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 0 32px;
}
.iframe-text-password ::v-deep b {
  color: #52545d;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.iframe-login-div {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px 24px;
}
.iframe-sso-btn {
  color: #4689f3;
}
.iframe-or-div {
  position: relative;
  width: 100%;
  height: 16px;
  margin: 12px 0;
}
.iframe-or-div .or-line {
  border-top: 1px solid #d7d7d7;
}
.iframe-or-div .or-text {
  width: 48px;
  color: #52545d;
  background-color: white;
}
.iframe-bottom-div {
  position: absolute;
  width: calc(100% - 48px);
  height: 36px;
  bottom: 20px;
}
.iframe-email-box {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 96px);
  height: 100%;
}
.iframe-input-box {
  position: relative;
  width: 100%;
  height: 36px;
}
.iframe-input-lable {
  position: absolute;
  left: 8px;
  top: -8px;
  height: 16px;
  background-color: #ffffff;
  color: #3f3f3f73;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 0 4px;
}
.iframe-login-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 88px;
  height: 100%;
}
.iframe-input-field {
  position: relative;
  width: 100%;
  height: 36px !important;
  overflow: hidden !important;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}
.iframe-input-field ::v-deep .v-text-field__slot input {
  margin: 1px 0 !important;
  font-size: 14px;
  line-height: 20px;
}
.iframe-input-field ::v-deep .v-input__append-inner {
  margin: 6px 0 !important;
}
.iframe-forgot-password {
  position: relative;
  color: #4689f3;
  font-size: 14px;
  line-height: 14px;
  margin-top: 8px;
  text-align: right;
  cursor: pointer;
}
.iframe-back-btn {
  position: absolute;
  top: 20px;
  left: 14px;
  height: 32px;
  width: 32px;
  padding-top: 5px;
}
.deephow-logo {
  width: 227px;
  margin: 32px auto 0 auto;
}
</style>
