<template>
  <div class="aiAssistance">
    <div class="aiAssistance--title">{{ $t("admin.aiAssistance") }}</div>
    <admin-setting-ai-assistance
      v-if="getter_quiz_enabled"
      @switchQuizSetting="switchQuizSetting"
    ></admin-setting-ai-assistance>

    <admin-setting-multi-modal-sop
      v-if="getter_multi_modal_sop_feature_enabled"
      @updateMultiModalSopSetting="updateMultiModalSopSetting"
    ></admin-setting-multi-modal-sop>

    <admin-setting-ai-qa
      v-if="getter_ai_qa_feature_enabled"
      @updateAiQaSetting="updateAiQaSetting"
    ></admin-setting-ai-qa>
  </div>
</template>

<script>
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import AdminSettingAiAssistance from "@/components/DAdmin/Settings/AdminSettingAiAssistance.vue";
import AdminSettingMultiModalSop from "@/components/DAdmin/Settings/AdminSettingMultiModalSop.vue";
import AdminSettingAiQa from "@/components/DAdmin/Settings/AdminSettingAiQa.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DAdminAiAssistance",
  components: {
    AdminSettingAiAssistance,
    AdminSettingMultiModalSop,
    AdminSettingAiQa,
  },
  mixins: [MixinUser, MixinDB],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("permission", [
      "getter_quiz_enabled",
      "getter_multi_modal_sop_feature_enabled",
      "getter_ai_qa_feature_enabled",
    ]),
  },
  mounted() {
    this.getOrgPermissionsSettings({ organization: this.$organization });
  },
  methods: {
    ...mapActions("global", ["openAlert"]),
    ...mapActions("permission", [
      "getOrgPermissionsSettings",
      "updateQuizSettings",
      "updateMultiModalSopSettings",
      "updateAiQaSettings",
    ]),
    async switchQuizSetting(enabled) {
      const updateData = { aiAssistance: { quiz: enabled } };
      const successMessage = enabled
        ? this.$t("admin.alertEnableAiPoweredQuizzingSuccess")
        : this.$t("admin.alertDisableAiPoweredQuizzingSuccess");
      const errorMessage = enabled
        ? this.$t("admin.alertEnableAiPoweredQuizzingFailed")
        : this.$t("admin.alertDisableAiPoweredQuizzingFailed");
      try {
        await this.updateQuizSettings({ organization: this.$organization, updateData });
        this.openAlert({ type: "success", message: successMessage });
      } catch (error) {
        this.openAlert({ type: "error", message: errorMessage });
      }
    },
    async updateMultiModalSopSetting(enabled) {
      const updateData = { aiAssistance: { multiModalSOP: enabled } };
      const successMessage = enabled
        ? this.$t("admin.alertEnableAiGenerateMultiModalSopSuccess")
        : this.$t("admin.alertDisableAiGenerateMultiModalSopSuccess");
      const errorMessage = enabled
        ? this.$t("admin.alertEnableAiGenerateMultiModalSopFailed")
        : this.$t("admin.alertDisableAiGenerateMultiModalSopFailed");
      try {
        await this.updateMultiModalSopSettings({ organization: this.$organization, updateData });
        this.openAlert({ type: "success", message: this.$t(successMessage) });
      } catch (error) {
        this.openAlert({ type: "error", message: this.$t(errorMessage) });
      }
    },
    async updateAiQaSetting(enabled) {
      const updateData = { aiAssistance: { AIQA: enabled } };
      const successMessage = enabled
        ? this.$t("admin.alertEnableWorkflowQaWithAiSuccess")
        : this.$t("admin.alertDisableWorkflowQaWithAiSuccess");
      const errorMessage = enabled
        ? this.$t("admin.alertEnableWorkflowQaWithAiFailed")
        : this.$t("admin.alertDisableWorkflowQaWithAiFailed");
      try {
        await this.updateAiQaSettings({ organization: this.$organization, updateData });
        this.openAlert({ type: "success", message: this.$t(successMessage) });
      } catch (error) {
        this.openAlert({ type: "error", message: this.$t(errorMessage) });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.aiAssistance {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  padding-bottom: 8px;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;

  &--title {
    font-family: Roboto;
    letter-spacing: 0;
    color: #3f3f3f;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 20px;
  }
}
</style>
