<template>
  <d-player-setting-options
    :options="options"
    valueAttr="code"
    labelAttr="name"
    :defaultValue="currentAudioChoice"
    @change-value="changeText2Speech($event)"
  />
</template>

<script>
/** NOTICE **
 * It have been removed from player setting,
 * but may added in the future
 */
import DPlayerSettingOptions from "@/components/DWorkflowPlayer/setting/DPlayerSettingOptions.vue";
import {
  getUniversalLanguageFullName,
  sortLanguageCodeByFullName,
  getLanguageOptionList,
} from "@/js/workflow/language.js";
import { loggingError } from "@/server/error-log-server.js";

export default {
  name: "DPlayerSettingText2Speech",
  components: { DPlayerSettingOptions },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: Number,
    },
    currentAudioChoice: {
      type: String,
    },
    languageFullName: {
      type: Object,
    },
  },
  computed: {
    async audioOptions() {
      let options = [];
      try {
        if (this.steps[this.currentStep].foreignLanguageAudio) {
          options = Object.keys(this.steps[this.currentStep].foreignLanguageAudio);
        }
      } catch (e) {
        console.error(e);
        loggingError(e);
      } finally {
        return options;
      }
    },
    options() {
      const dictionaryMap = getUniversalLanguageFullName(this.$dictionary);
      const sortedLangCodeList = sortLanguageCodeByFullName(this.audioOptions, dictionaryMap);
      const list = getLanguageOptionList(sortedLangCodeList, dictionaryMap);
      const languageCode = this.steps[this.currentStep].languageCode;
      const firstOption = {
        code: languageCode,
        name: dictionaryMap[languageCode],
      };
      return [firstOption, ...list];
    },
  },
  methods: {
    changeText2Speech({ value }) {
      this.$emit("change-text2-speech", { audioChoice: value });
    },
  },
};
</script>
