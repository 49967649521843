<template>
  <section class="ipRestrictionPopup">
    <v-card width="466" color="#FFFFFF" class="ipRestrictionPopup--card">
      <button @click="$emit('close-popup')" :autotest="getAutotest('close')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="ipRestrictionPopup--formWrapper">
        <div class="ipRestrictionPopup--formIcon">
          <d-icon-ipRestriction />
        </div>
        <div>
          <div class="ipRestrictionPopup--formTitle">
            <span v-if="showPopupType === 'create'">{{ $t("admin.addWhitelistedIpRule") }}</span>
            <span v-if="showPopupType === 'edit'">{{ $t("admin.editWhitelistedRule") }}</span>
          </div>
          <div>
            <v-form v-model="formValid" @submit.prevent autocomplete="off" class="ipRestrictionPopup--form">
              <d-text-field
                autofocus
                v-model="whitelistedIpDescription"
                :label="$t('admin.whitelistedIpDescription') + '*'"
                :rules="[rules.required, rules.noAllSpace, rules.whitelistedIpDescriptionAllowed]"
                autocomplete="chrome-off"
                @input="madeChanges = true"
                :autotest="getAutotest('description-input')"
              ></d-text-field>

              <d-select
                v-model="selectedSourceFilter"
                :items="whitelistedIpSourceFilters"
                :label="$t('admin.whitelistedIpSourceFilter') + '*'"
                @change="madeChanges = true"
                :autotest="getAutotest('source-filters-dropdown')"
                dense
              ></d-select>

              <d-text-field
                v-for="(data, index) in whitelistedIpsOrRange"
                v-model="data.rule"
                :label="index === 0 ? $t('admin.whitelistedIpsOrRange') + '*' : ''"
                :key="data.id"
                autocomplete="chrome-off"
                @input="madeChanges = true"
                :autotest="getAutotest('ips-range-input')"
                append-icon="delete"
                @click-append="removeIpsOrRangeField(index)"
                :rules="index === whitelistedIpsOrRange.length - 1 ? [rules.required, rules.noAllSpace] : []"
                class="ipRestrictionPopup--removeIpsOrRangeField"
                :class="
                  whitelistedIpsOrRange.length <= 1
                    ? 'ipRestrictionPopup--removeIpsOrRangeField__disabled'
                    : 'ipRestrictionPopup--removeIpsOrRangeField__active'
                "
              ></d-text-field>

              <div
                @click="addIpsOrRangeField"
                class="ipRestrictionPopup--addIpsOrRangeField"
                :class="{
                  'ipRestrictionPopup--addIpsOrRangeField__disabled':
                    !whitelistedIpsOrRange[whitelistedIpsOrRange.length - 1].rule.trim(),
                }"
              >
                + {{ $t("admin.addAnotherIp") }}
              </div>
            </v-form>
          </div>

          <v-card-actions class="ipRestrictionPopup--actionButtons">
            <v-layout xs12>
              <v-flex xs6 mr-2>
                <v-btn
                  outline
                  depressed
                  round
                  class="body-2 text-capitalize"
                  color="#4689F3"
                  @click="$emit('close-popup')"
                  :autotest="getAutotest('cancel')"
                  >{{ $t("all.cancel") }}</v-btn
                >
              </v-flex>
              <v-flex xs6 ml-2>
                <v-btn
                  v-if="showPopupType === 'create'"
                  depressed
                  round
                  class="body-2 white--text text-capitalize ml-0"
                  color="#4689F3"
                  :disabled="!formValid"
                  @click="updateIpsOrRanges"
                  autotest="add-ips-or-range-popup-add-btn"
                  >{{ $t("all.add") }}</v-btn
                >
                <v-btn
                  v-if="showPopupType === 'edit'"
                  depressed
                  round
                  class="body-2 white--text text-capitalize ml-0"
                  color="#4689F3"
                  :disabled="!formValid || !madeChanges"
                  @click="updateIpsOrRanges"
                  autotest="edit-ips-or-range-popup-save-btn"
                  >{{ $t("all.save") }}</v-btn
                >
              </v-flex>
            </v-layout>
          </v-card-actions>
        </div>
      </div>
    </v-card>
    <div class="ipRestrictionPopup--closePopup" @click="$emit('close-popup')"></div>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconIpRestriction from "@/components/icons/admin/DIconIpRestriction.vue";
import DSelect from "@/components/ui_components/DSelect.vue";
import DDropdown from "@/components/ui_components/DDropdown.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import { checkAdminCommonCharacters } from "@/js/character-validator/character-validator.js";
import { getUuidv4 } from "@/server/upload-server.js";
import { ipVersionMap, reversedIpVersionMap } from "@/constants/ipVersion.js";

export default {
  name: "AdminSettingIpRestrictionPopup",
  props: {
    showPopupType: String,
    editWhitelistedIp: Object,
  },
  components: {
    IconBase,
    DIconClose2,
    DIconIpRestriction,
    DDropdown,
    DSelect,
    DTextField,
  },
  data() {
    return {
      whitelistedIpDescription: "",
      whitelistedIpSourceFilters: ["IPv4", "IPv6"],
      selectedSourceFilter: "IPv4",
      whitelistedIpsOrRange: [{ id: getUuidv4(), rule: "" }],
      formValid: false,
      madeChanges: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        whitelistedIpDescriptionAllowed: () => !this.whitelistedIpDescriptionNotAllowed || this.$t("rules.invalidName"),
      },
    };
  },
  computed: {
    whitelistedIpDescriptionNotAllowed() {
      return checkAdminCommonCharacters(this.whitelistedIpDescription);
    },
  },
  created() {
    this.setEditFormData();
  },
  methods: {
    setEditFormData() {
      if (this.showPopupType === "edit") {
        const { name, type, rules } = this.editWhitelistedIp;
        this.whitelistedIpDescription = name;
        this.selectedSourceFilter = ipVersionMap[type];
        this.whitelistedIpsOrRange = rules.map((rule) => ({ id: getUuidv4(), rule }));
      }
    },
    removeIpsOrRangeField(index) {
      this.whitelistedIpsOrRange.splice(index, 1);
      this.madeChanges = true;
    },
    addIpsOrRangeField() {
      this.whitelistedIpsOrRange.push({ id: getUuidv4(), rule: "" });
    },
    updateIpsOrRanges() {
      const type = reversedIpVersionMap[this.selectedSourceFilter];
      this.$emit("updateWhitelistedIp", {
        id: this.editWhitelistedIp.id,
        name: this.whitelistedIpDescription,
        type,
        rules: this.whitelistedIpsOrRange.map((data) => data.rule),
      });
      this.$emit("close-popup");
    },
    getAutotest(tag) {
      const tagsMapping = {
        close: `${this.showPopupType}-ips-or-range-popup-close-btn`,
        "description-input": `${this.showPopupType}-ips-or-range-popup-description-input`,
        "source-filters-dropdown": `${this.showPopupType}-ips-or-range-popup-source-filters-dropdown`,
        "ips-range-input": `${this.showPopupType}-ips-or-range-popup-ips-range-input`,
        cancel: `${this.showPopupType}-ips-or-range-popup-cancel-btn`,
      };
      return tagsMapping[tag] || "";
    },
  },
};
</script>

<style scoped lang="scss">
.ipRestrictionPopup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;

  &--card {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 24px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
    max-height: 100%;
    overflow: scroll;

    & > button {
      position: absolute;
      right: 24px;
      top: 24px;
      z-index: 1;
    }
  }

  &--formWrapper {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 28px 20px 0 20px;
  }

  &--formIcon {
    margin-right: 20px;
  }

  &--formTitle {
    color: #52545d;
    font-family: Roboto;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    margin-top: 12px;
  }

  &--form {
    margin: 42px 0;
  }

  &--removeIpsOrRangeField {
    padding-bottom: 0;

    &__disabled ::v-deep .v-icon.v-icon.v-icon--link {
      opacity: 30% !important;
      color: #fc585b !important;
      cursor: auto;
      pointer-events: none;
    }

    &__active {
      ::v-deep .v-icon.v-icon.v-icon--link {
        opacity: 1 !important;
        color: #fc585b !important;
      }
    }
  }

  &--addIpsOrRangeField {
    border: 1px dashed #c7cedd;
    padding: 8px;
    text-align: left;
    border-radius: 4px;
    margin-top: 20px;

    &:hover {
      cursor: pointer;
    }

    &__disabled {
      cursor: auto;
      pointer-events: none;
      color: #c7cedd;
    }
  }

  &--actionButtons button {
    width: 100%;
  }

  &--closePopup {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
</style>
