<template>
  <section class="mixin-video"></section>
</template>

<script>
import MixinUpload from "./MixinUpload.vue";
import Downloadjs from "downloadjs";
import axios from "axios";
import MixinUser from "./MixinUser.vue";
import environment from "../../env.js";
import { getSignedUrlByRez } from "@/server/sign-server.js";
import { cloneDeep } from "lodash-es";

const env = environment.env;
const handlerURL = environment.service[env].china ? environment.service[env].china.handler : "";

let audioBufferToWav = require("../js/audioBufferToWave.js");
export default {
  name: "mixin-video",
  props: [],
  mixins: [MixinUpload, MixinUser],
  mounted() {},
  data() {
    return {};
  },
  methods: {
    downloadBlobVideo(link, id, format = ".mp4", mime = "video/mp4", onprogressCallback) {
      return new Promise(function (resolve, reject) {
        const requestFn = function (videoLink) {
          var x = new XMLHttpRequest();
          x.open("GET", videoLink, true);
          x.responseType = "blob";
          x.onprogress = function (event) {
            onprogressCallback && onprogressCallback(event);
          };
          x.onload = function (e) {
            if (e.target.status === 200) {
              Downloadjs(e.target.response, id + format, mime);
              resolve();
              return;
            }
            reject(e);
          };
          x.onerror = function (err) {
            reject(err);
          };
          try {
            x.send();
          } catch (err) {
            reject(err);
          }
        };

        requestFn(link);
      });
    },
    toBase64(src) {
      return new Promise(function (resolve, reject) {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
          var canvas = document.createElement("CANVAS");
          var ctx = canvas.getContext("2d");
          var dataURL;
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          dataURL = canvas.toDataURL();
          resolve(dataURL);
        };

        img.src = src;
      });
    },
    fileToBlob(file) {
      return new Promise(function (resolve, reject) {
        try {
          const reader = new FileReader();
          reader.addEventListener("loadend", function () {
            const url = reader.result;
            fetch(url)
              .then((res) => res.blob())
              .then(function (blob) {
                resolve(blob);
              });
          });
          reader.readAsDataURL(file);
        } catch (err) {
          reject(err);
        }
      });
    },
    //input can be video or audio file/blob
    toWav(file) {
      return new Promise(function (resolve, reject) {
        try {
          let reader = new FileReader();
          let audioContext = new AudioContext();
          reader.readAsArrayBuffer(file);
          reader.onload = function () {
            let videoFileAsBuffer = reader.result; // arraybuffer
            audioContext.decodeAudioData(videoFileAsBuffer).then(function (decodedAudioData) {
              const sampleRate = 16000; //sample rate higher than 16000 have no appreciable effect on speech recognition quality.
              const numberOfChannels = 1;
              let duration = decodedAudioData.duration;
              let offlineAudioContext = new OfflineAudioContext(numberOfChannels, sampleRate * duration, sampleRate);
              let soundSource = offlineAudioContext.createBufferSource();
              let myBuffer = decodedAudioData;
              soundSource.buffer = myBuffer;
              soundSource.connect(offlineAudioContext.destination);
              soundSource.start();
              offlineAudioContext.startRendering();
              offlineAudioContext.oncomplete = function (e) {
                let wav = audioBufferToWav.audioBufferToWav(e.renderedBuffer);
                let blob = new window.Blob([new DataView(wav)], {
                  type: "audio/wav",
                });
                resolve(blob);
              };
            });
          };
        } catch (err) {
          reject(err);
        }
      });
    },
    getVideoDuration(video) {
      return new Promise(function (resolve, reject) {
        try {
          let duration;
          const videoPlayer = document.createElement("video");
          videoPlayer.addEventListener("loadedmetadata", function () {
            if (videoPlayer.duration === Infinity) {
              videoPlayer.currentTime = Number.MAX_SAFE_INTEGER;
              videoPlayer.ontimeupdate = () => {
                videoPlayer.ontimeupdate = null;
                duration = videoPlayer.duration;
                resolve(duration);
              };
            } else {
              duration = videoPlayer.duration;
              resolve(duration);
            }
          });
          const usingVideoUrl = window.URL.createObjectURL(video);

          videoPlayer.src = usingVideoUrl;
        } catch (err) {
          reject(err);
        }
      });
    },
    getVideoPostersFromBackendBatch(videoUrl, posterSavePath, duration, posters) {
      const self = this;

      if (videoUrl.includes("?")) {
        videoUrl = videoUrl.substring(0, videoUrl.indexOf("?"));
      }
      if (videoUrl.includes("%2F")) {
        videoUrl = decodeURIComponent(videoUrl);
      }
      //TODO this is not good. try make this simple and atomic
      let usingBucket;
      if (videoUrl.indexOf(".aliyuncs.com/") > 0) {
        const checkHeadKey = "://";
        usingBucket = videoUrl.substring(
          videoUrl.indexOf(checkHeadKey) + checkHeadKey.length,
          videoUrl.indexOf(".oss-")
        );
      } else if (videoUrl.indexOf("gs://") == 0) {
        const checkHeadKey = "://";
        usingBucket = videoUrl
          .substring(
            videoUrl.indexOf(checkHeadKey) + checkHeadKey.length,
            videoUrl.indexOf("/" + self.$userProfile.organization)
          )
          .replace("/", "");
      } else {
        //https://firebasestorage.googleapis.com/v0/b/deephow-dev.appspot.com/o/CyvXxtF8W32MmrZM4AFy
        let tempBB = videoUrl
          .replace("https://firebasestorage.googleapis.com", "")
          .replace("/v0/b/", "")
          .replace("/o/", "");
        //https://storage.cloud.google.com/deephow-dev.appspot.com/CyvXxtF8W32MmrZM4AFy
        tempBB = tempBB.replace("https://storage.cloud.google.com/", "");
        usingBucket = tempBB
          .substring(0, tempBB.indexOf(self.$userProfile.organization))
          .replace("/", "")
          .replace("/", "");
      }

      //video should start with org like CyvXxtF8W32MmrZM4AFy/videos/.... which is under bucket org name
      videoUrl = videoUrl.substring(videoUrl.indexOf(self.$userProfile.organization));

      return new Promise(function (resolve, reject) {
        self
          .auth()
          .currentUser.getIdToken()
          .then((idToken) => {
            const data = {
              token: "rG5kXk0CDbhgF4RBlNoV",
              idToken: idToken,
              backendType: self.$backendType,
              bucket: usingBucket,
              target_folder: posterSavePath,
              video_url: videoUrl,
              video_duration: duration,
              organization: self.$userProfile.organization,
              posters: posters,
            };
            axios
              .post(self.$service.openCV + "extract-video-poster", data)
              .then((result) => {
                resolve(result.data);
              })
              .catch(reject);
          })
          .catch(reject);
      });
    },
    getVideoPosterFromBackend(videoUrl, posterSavePath, time, duration, width, height) {
      const self = this;

      if (!time) {
        time = 2;
      }
      if (!width) {
        width = 495;
      }
      if (!height) {
        height = 270;
      }
      if (videoUrl.includes("?")) {
        videoUrl = videoUrl.substring(0, videoUrl.indexOf("?"));
      }
      if (videoUrl.includes("%2F")) {
        videoUrl = decodeURIComponent(videoUrl);
      }
      //TODO this is not good. try make this simple and atomic
      let usingBucket;
      if (videoUrl.indexOf(".aliyuncs.com/") > 0) {
        const checkHeadKey = "://";
        usingBucket = videoUrl.substring(
          videoUrl.indexOf(checkHeadKey) + checkHeadKey.length,
          videoUrl.indexOf(".oss-")
        );
      } else if (videoUrl.indexOf("gs://") == 0) {
        const checkHeadKey = "://";
        usingBucket = videoUrl
          .substring(
            videoUrl.indexOf(checkHeadKey) + checkHeadKey.length,
            videoUrl.indexOf("/" + self.$userProfile.organization)
          )
          .replace("/", "");
      } else {
        //https://firebasestorage.googleapis.com/v0/b/deephow-dev.appspot.com/o/CyvXxtF8W32MmrZM4AFy
        let tempBB = videoUrl
          .replace("https://firebasestorage.googleapis.com", "")
          .replace("/v0/b/", "")
          .replace("/o/", "");
        //https://storage.cloud.google.com/deephow-dev.appspot.com/CyvXxtF8W32MmrZM4AFy
        tempBB = tempBB.replace("https://storage.cloud.google.com/", "");
        usingBucket = tempBB
          .substring(0, tempBB.indexOf(self.$userProfile.organization))
          .replace("/", "")
          .replace("/", "");
      }

      //video should start with org like CyvXxtF8W32MmrZM4AFy/videos/.... which is under bucket org name
      videoUrl = videoUrl.substring(videoUrl.indexOf(self.$userProfile.organization));

      return new Promise(function (resolve, reject) {
        self
          .auth()
          .currentUser.getIdToken()
          .then((idToken) => {
            const data = {
              token: "rG5kXk0CDbhgF4RBlNoV",
              idToken: idToken,
              backendType: self.$backendType,
              bucket: usingBucket,
              target_folder: posterSavePath,
              video_url: videoUrl,
              timestamp: time,
              image_width: width,
              image_height: height,
              video_duration: duration,
              organization: self.$userProfile.organization,
            };
            axios
              .post(self.$service.openCV + "extract-video-poster", data)
              .then((result) => {
                resolve(result.data);
              })
              .catch(reject);
          })
          .catch(reject);
      });
    },
    getVideoPoster(url, time, width, height) {
      const self = this;
      if (!time) {
        time = 2;
      }
      if (!width) {
        width = 495;
      }
      if (!height) {
        height = 270;
      }
      return new Promise(function (resolve, reject) {
        let video = document.createElement("video");
        video.setAttribute("crossorigin", "anonymous");
        let poster;
        video.src = url;
        //jump the video to the timestamp
        video.onloadedmetadata = function () {
          if (this.videoHeight > this.videoWidth) {
            [width, height] = [height, width]; //swap ratio
          }
          this.currentTime = time;
        };
        //when jumping is ready
        video.onseeked = function () {
          let canvas = document.createElement("canvas");
          canvas.height = height;
          canvas.width = width;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          poster = new Image();
          canvas.toBlob(
            function (blob) {
              let imageFile = new File([blob], "image-" + self.uuidv4() + ".jpg", {
                type: "image/jpeg",
              });
              poster.src = window.URL.createObjectURL(blob);
              resolve(imageFile);
            },
            "image/jpeg",
            0.9
          );
        };
      });
    },
    getAudioFromVideo(video) {
      const self = this;
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let audioContext = new AudioContext();
        reader.readAsArrayBuffer(video);
        reader.onload = function () {
          let videoFileAsBuffer = reader.result; // arraybuffer
          audioContext.decodeAudioData(videoFileAsBuffer).then(function (decodedAudioData) {
            const sampleRate = 16000; //sample rate higher than 16000 have no appreciable effect on speech recognition quality.
            const numberOfChannels = 1;
            let duration = decodedAudioData.duration;
            let offlineAudioContext = new OfflineAudioContext(numberOfChannels, sampleRate * duration, sampleRate);
            let soundSource = offlineAudioContext.createBufferSource();
            let myBuffer = decodedAudioData;
            soundSource.buffer = myBuffer;
            soundSource.connect(offlineAudioContext.destination);
            soundSource.start();
            offlineAudioContext.startRendering();
            offlineAudioContext.oncomplete = function (e) {
              let wav = audioBufferToWav.audioBufferToWav(e.renderedBuffer);
              let blob = new window.Blob([new DataView(wav)], {
                type: "audio/wav",
              });
              resolve(blob);
            };
          });
        };
      });
    },
    getVideoResolution(video) {
      return new Promise(function (resolve, reject) {
        const player = document.createElement("video");
        player.onloadedmetadata = function (event) {
          const resolution = { width: this.videoWidth, height: this.videoHeight };
          resolve(resolution);
        };

        player.src = video;
      });
    },
    async handleCloudFrontStepUrlSignByRez(sourceSteps) {
      // prevent update sourceSteps (js reference issue)
      // only update cloneSteps and return cloneSteps.
      const cloneSteps = cloneDeep(sourceSteps);
      try {
        const waitGetSignedUrlResultPromises = [];
        for (const step of cloneSteps) {
          if (!step.videos) {
            continue;
          }

          for (let rez of Object.keys(step.videos)) {
            if (!step.videos[rez]) {
              continue;
            }
            waitGetSignedUrlResultPromises.push(
              new Promise((resolve) => {
                getSignedUrlByRez("step", step.id, rez).then((result) => {
                  if (result.ok) {
                    step.videos[rez] = result.url;
                  }
                  resolve(step);
                });
              })
            );
          }
        }
        await Promise.all(waitGetSignedUrlResultPromises);
        return { ok: true, steps: cloneSteps };
      } catch (e) {
        console.log(e);
        return { ok: false, steps: cloneSteps };
      }
    },
    getVideoPosterFromHandlerServer(url, time, width, height) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        let posterUrl = null;
        let maxCount = 5;
        let retryCount = 0;
        if (!time) {
          time = 2;
        }
        if (!width) {
          width = 495;
        }
        if (!height) {
          height = 270;
        }
        let data = {
          url: url.split("#")[0],
          time: Math.round(time * 1000),
          width: width,
          height: height,
          token: "d4d8fb359b5f9be9399b82e71729a737",
        };

        let idToken = await self.auth().currentUser.getIdToken();
        let libPath = handlerURL + "getposter";

        try {
          while (retryCount <= maxCount && !posterUrl) {
            posterUrl = await self.callApiFromHandlerServer(libPath, data, retryCount, idToken);
            retryCount++;
          }

          if (!posterUrl) {
            return reject("Get Video Poster From Handler Server Failed");
          }

          return resolve(posterUrl);
        } catch (error) {
          return reject(error);
        }
      });
    },

    callApiFromHandlerServer(libPath, data, retryCount, idToken) {
      return new Promise(function (resolve, reject) {
        const timeout = 1000 * retryCount;
        setTimeout(async () => {
          axios
            .post(
              libPath,
              {
                data: data,
              },
              {
                headers: {
                  "id-token": idToken,
                },
              }
            )
            .then((url) => {
              return resolve(url.data.url);
            })
            .catch((error) => {
              return resolve(false);
            });
        }, timeout);
      });
    },
  },
  computed: {},
};
</script>
