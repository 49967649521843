import { render, staticRenderFns } from "./AdminSettingMultiModalSop.vue?vue&type=template&id=0b278b8b&scoped=true&"
import script from "./AdminSettingMultiModalSop.vue?vue&type=script&lang=js&"
export * from "./AdminSettingMultiModalSop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b278b8b",
  null
  
)

export default component.exports