<template>
  <v-dialog
    max-width="640"
    persistent
    :value="isOpenConfirmedPublishPopup"
    content-class="builderPublishConfirmedPopup"
    @input="handleClose"
  >
    <v-card class="card">
      <div class="close-btn" autotest="popup-close-btn" @click="handleClose"></div>
      <v-card-text class="card__text">
        <d-icon-published class="icon" />
        <h3 class="title">{{ $t("builder.publishWorkflow") }}</h3>
        <div class="subtitle" v-html="$t('builder.confirmedPublished')" />
        <div class="description" v-if="!isInitializedWorkflow">
          <div class="description__title">{{ $t("builder.versionDescription", { num: versionNumber }) }}</div>
          <textarea
            class="description__input"
            @input="$emit('update:versionDescription', $event.target.value)"
            :value="versionDescription"
          ></textarea>
          <div class="checkbox">
            <input type="checkbox" id="re-training" v-model="isReTraining" />
            <label for="re-training">
              <d-icon-checked-box v-if="isReTraining" />
              <d-icon-check-box v-else />
              {{ $t("quiz.reTrainWarning") }}
            </label>
            <d-icon-circle-question />
            <div class="tooltip">
              <d-icon-circle-question fill="#4689f4" />
              <p>{{ $t("quiz.reTrainTooltipWarning") }}</p>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="card__actions">
        <d-button
          analytics="confirm-close-buildermain"
          autotest="popup-cancel-btn"
          outline
          round
          color="#4689f4"
          class="text-capitalize card__actions--btn"
          light
          @click="handleClose"
          >{{ $t("all.cancel") }}
        </d-button>
        <d-button
          analytics="confirm-publish"
          autotest="popup-yes-btn"
          round
          color="#4689f4"
          dark
          class="text-capitalize card__actions--btn"
          @click="$emit('confirmPublish', { nextVersionHistoryNumber: Number(versionNumber) || 1, isReTraining })"
          >{{ $t("builder.publish") }}
        </d-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchVersionHistoryByWorkflowId } from "@/server/version-history";
import { isEmpty } from "lodash-es";
import DIconCheckedBox from "@/components/icons/builder/DIconCheckedBox.vue";

export default {
  name: "DBuilderPublishConfirmedPopup",
  props: {
    isOpenConfirmedPublishPopup: Boolean,
    versionDescription: String,
    workflowId: String,
  },
  components: {
    DIconPublished: () => import("@/components/icons/builder/DIconPublished.vue"),
    DIconCheckBox: () => import("@/components/icons/builder/DIconCheckBox.vue"),
    DIconCircleQuestion: () => import("@/components/icons/builder/DIconCircleQuestion.vue"),
    DIconCheckedBox,
  },
  methods: {
    handleClose() {
      this.$emit("update:isOpenConfirmedPublishPopup", false);
      this.$emit("update:versionDescription", "");
    },
    async refreshVersionHistoryData(workflowId) {
      const { ok, data, errorMessage } = await fetchVersionHistoryByWorkflowId(workflowId);
      if (!ok) {
        console.error(errorMessage);
        return;
      }
      this.versionHistoryData = data.items.sort((a, b) => b.version - a.version);
    },
  },
  data() {
    return {
      versionHistoryData: [],
      isReTraining: false,
    };
  },
  computed: {
    latestVersionNumber() {
      if (this.versionHistoryData.length === 0) {
        return {};
      }
      return this.versionHistoryData[0];
    },
    isInitializedWorkflow() {
      return this.latestVersionNumber.actionType === "initialize" || this.versionHistoryData.length === 0;
    },
    versionNumber() {
      if (isEmpty(this.latestVersionNumber)) {
        return "";
      }
      return (Number(this.latestVersionNumber.version) + 1).toFixed(1);
    },
  },
  watch: {
    isOpenConfirmedPublishPopup: {
      immediate: true,
      handler(isOpenConfirmedPublishPopup) {
        if (!isOpenConfirmedPublishPopup) {
          return;
        }
        this.isReTraining = false;
        this.refreshVersionHistoryData(this.workflowId);
      },
    },
    workflowId: {
      immediate: true,
      handler(workflowId) {
        if (!workflowId) {
          return;
        }
        this.refreshVersionHistoryData(workflowId);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.builderPublishConfirmedPopup {
  .card {
    position: relative;
    background-color: #1e1f22;

    .close-btn {
      --roate-angle: 45deg;
      width: 16px;
      height: 16px;
      position: absolute;
      cursor: pointer;
      right: 24px;
      top: 24px;
      z-index: 10;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #9397a6;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(var(--roate-angle));
      }

      &:after {
        --roate-angle: -45deg;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 48px 32px 0 32px;
      .icon {
        margin-bottom: 24px;
      }
      .title {
        color: #fff;
        font-size: 20px;
        margin-bottom: 4px;
      }

      .subtitle {
        color: #8d909f;
        font-size: 16px;
        margin-bottom: 48px;
      }

      .description {
        width: 100%;
        color: #fff;
        &__title {
          text-align: left;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 8px;
        }

        &__input {
          background-color: #121314;
          width: 100%;
          height: 100px;
          outline: none;
          padding: 0.5rem;
          resize: none;
          border-radius: 4px;
        }
      }
    }

    &__actions {
      padding: 32px;
      display: flex;
      align-items: center;
      gap: 32px;

      &--btn {
        flex: 1 0 auto;
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;
    user-select: none;
    cursor: pointer;
  }

  & > svg {
    cursor: pointer;

    &:hover + .tooltip {
      z-index: 1;
      opacity: 1;
    }
  }

  .tooltip {
    display: flex;
    position: absolute;
    z-index: -1;
    bottom: 115%;
    left: -8px;
    margin: 0;
    width: 540px;
    border: 1px solid var(--dGrey4-color);
    border-radius: 4px;
    background-color: var(--dGrey1-color);
    padding: 8px 16px;
    box-shadow: 0 2px 8px 0 #00000059;
    transition: opacity 0.3s ease-in;
    opacity: 0;
    gap: 16px;
    svg {
      flex: 0 0 16px;
    }
    p {
      margin: 0;
      text-align: left;
      color: #9397a6;
      font-size: 14px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      left: 49%;
      top: 100%;
      border-style: solid;
      border-width: 4px;
    }

    &:before {
      border-top-color: var(--dGrey1-color);
      z-index: 2;
    }

    &:after {
      transform: translateY(1px);
      border-top-color: var(--dGrey4-color);
    }
  }
}
</style>
