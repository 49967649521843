<template>
  <section>
    <admin-setting-permission-item
      beta
      class="aiAssistance--permissionItem"
      :allow="getter_ai_qa_enabled"
      :title="$t('admin.workflowQaWithAi')"
      :subtitle="$t('admin.workflowQaWithAiDescription')"
      autotest="ai-assistance-permission-toggle"
      @click-switch="showConfirmation = true"
    />

    <d-change-permission-confirmation
      v-if="showConfirmation"
      :title="$t('admin.workflowQaWithAi')"
      :content="
        getter_ai_qa_enabled ? $t('admin.disableWorkflowQaWithAiMessage') : $t('admin.enableWorkflowQaWithAiMessage')
      "
      @close-popup="showConfirmation = false"
      @change-permission="changeAiQaSetting"
    ></d-change-permission-confirmation>
  </section>
</template>

<script>
import AdminSettingPermissionItem from "@/components/DAdmin/Settings/AdminSettingPermissionItem.vue";
import DChangePermissionConfirmation from "@/components/DAdmin/confirmations/DChangePermissionConfirmation.vue";
import { mapGetters } from "vuex";

export default {
  name: "AdminSettingAiAssistance",
  components: {
    AdminSettingPermissionItem,
    DChangePermissionConfirmation,
  },
  data() {
    return {
      showConfirmation: false,
    };
  },
  computed: {
    ...mapGetters("permission", ["getter_ai_qa_enabled"]),
  },
  methods: {
    changeAiQaSetting() {
      this.showConfirmation = false;
      this.$emit("updateAiQaSetting", !this.getter_ai_qa_enabled);
    },
  },
};
</script>

<style lang="scss" scoped></style>
