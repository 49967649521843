import { render, staticRenderFns } from "./DSelectorWorkflowCard.vue?vue&type=template&id=219da054&scoped=true&"
import script from "./DSelectorWorkflowCard.vue?vue&type=script&lang=js&"
export * from "./DSelectorWorkflowCard.vue?vue&type=script&lang=js&"
import style0 from "./DSelectorWorkflowCard.vue?vue&type=style&index=0&id=219da054&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219da054",
  null
  
)

export default component.exports