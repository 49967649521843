import Vue from "vue";
import { fetchWorkflowPlayerData, fetchPublicWorkflowPlayerData } from "@/server/player-server";
import { checkIsSignedUrl, getBatchSignedURLs } from "@/server/sign-server";
import { ADDON_TYPE } from "@/constants/attachmentStatus";
import { REPEAT_TYPES } from "@/constants/workflow-repeat-status.js";

export const namespaced = true;

const getStepAttachments = (attachments) => {
  if (!attachments || Object.keys(attachments).length === 0) {
    return [];
  }
  let items = [];
  if (attachments.diagrams && attachments.diagrams.length > 0) {
    const diagrams = {
      type: ADDON_TYPE.DIAGRAM,
      diagrams: attachments.diagrams,
    };
    items.push(diagrams);
  }
  if (attachments.links && attachments.links.length > 0) {
    const links = {
      type: ADDON_TYPE.LINKS,
      links: attachments.links,
    };
    items.push(links);
  }
  if (attachments.pdfs && attachments.pdfs.length > 0) {
    const pdfs = {
      type: ADDON_TYPE.PDF,
      pdfs: attachments.pdfs,
    };
    items.push(pdfs);
  }
  return items;
};

export const state = {
  isMobileFullscreen: false,
  isDesktopFullscreen: false,
  isTheaterMode: false,
  currentStepIdx: -1,
  isTypingWithPlayer: false,
  workflowData: {},
  showErrorMsg: false,
  selectedAttachmentStep: {},
  attachmentsPopupConfig: {
    selectedDiagramIdx: -1,
    isOpen: false,
  },
  isWorkflowURLsUpdated: false,
  videoElement: null,
  currentRepeatType: REPEAT_TYPES.DISABLE,
  isQaTabOpen: false,
};

export const getters = {
  getters_steps(state) {
    if (Object.keys(state.workflowData).length === 0) {
      return [];
    } else {
      return state.workflowData.stepsData || [];
    }
  },
  getter_attachments(_, getters) {
    let attachmentsData = [];
    for (let index = 0; index < getters.getters_steps.length; index++) {
      const attachments = getters.getters_steps[index].attachments;
      const filteredItems = getStepAttachments(attachments);
      attachmentsData.push(filteredItems);
    }
    return attachmentsData;
  },
  is_workflow_available(state) {
    return Object.keys(state.workflowData).length > 0 && !state.showErrorMsg;
  },
  getter_mobile_divided_screen(state, getters) {
    return getters.getter_show_attachments_popup_fullscreen && state.isMobileFullscreen;
  },
  getter_show_attachments_popup_fullscreen(state) {
    return state.attachmentsPopupConfig.isOpen;
  },
  getter_show_attachments_list(state) {
    return Object.keys(state.selectedAttachmentStep).length !== 0;
  },
  getter_selected_attachment_step(state) {
    return state.selectedAttachmentStep;
  },
  getter_current_step_idx(state) {
    return state.currentStepIdx;
  },
  getter_current_step_attachments(_, getters) {
    return getters.getter_attachments[getters.getter_selected_attachment_step.stepIndex] || [];
  },
  getter_current_step_attachments_diagrams(_, getters) {
    let diagrams = [];
    getters.getter_current_step_attachments.forEach((attachment) => {
      if (attachment.type === "diagram") {
        diagrams = attachment.diagrams;
        return diagrams;
      }
    });
    return diagrams;
  },
  getter_quiz_info: (state) => state.workflowData.usersQuizzes,
  getter_is_window_mode(state) {
    return !state.isTheaterMode && !state.isDesktopFullscreen && !state.isMobileFullscreen;
  },
  getter_current_repeat_type: (state) => state.currentRepeatType,
  getter_is_disabled_repeat: (state) => state.currentRepeatType === REPEAT_TYPES.DISABLE,
  getter_is_repeat_workflow: (state) => state.currentRepeatType === REPEAT_TYPES.WORKFLOW,
  getter_is_repeat_step: (state) => state.currentRepeatType === REPEAT_TYPES.STEP,
  getter_is_show_quiz: (state) => state.workflowData?.usersQuizzes?.published ?? false,
  getter_is_wait_to_quiz: (state) =>
    state.workflowData.usersQuizzes &&
    !state.workflowData.usersQuizzes.status &&
    state.workflowData.usersQuizzes.published,
  getter_sop_content: (state) => state.workflowData.multimodalSop?.content ?? [],
};

export const mutations = {
  SET_IS_QA_TAB_OPEN(state, isOpen) {
    state.isQaTabOpen = isOpen;
  },
  ENTER_MOBILE_FULLSCREEN(state) {
    state.isMobileFullscreen = true;
  },
  EXIT_MOBILE_FULLSCREEN(state) {
    state.isMobileFullscreen = false;
  },
  ENTER_DESKTOP_FULLSCREEN(state) {
    state.isDesktopFullscreen = true;
  },
  EXIT_DETSKTOP_FULLSCREEN(state) {
    state.isDesktopFullscreen = false;
  },
  ENTER_DESKTOP_THEATER_MODE(state) {
    state.isTheaterMode = true;
  },
  EXIT_DETSKTOP_THEATER_MODE(state) {
    state.isTheaterMode = false;
  },
  SET_CURRENT_STEP_IDX(state, index) {
    state.currentStepIdx = index;
  },
  SET_IS_START_TYPING(state) {
    state.isTypingWithPlayer = true;
  },
  SET_IS_END_TYPING(state) {
    state.isTypingWithPlayer = false;
  },
  HIDE_ERROR_MESSAGE(state) {
    state.showErrorMsg = false;
  },
  SHOW_ERROR_MESSAGE(state) {
    state.showErrorMsg = true;
  },
  SET_SELECTED_ATTACHMENT_STEP(state, step) {
    state.selectedAttachmentStep = step;
  },
  SET_ATTACHMENTS_POPUP(state, { selectedDiagramIdx, isOpen }) {
    state.attachmentsPopupConfig.selectedDiagramIdx = selectedDiagramIdx;
    state.attachmentsPopupConfig.isOpen = isOpen;
  },
  STORE_WORKFLOW_DATA(state, { workflow }) {
    state.workflowData = workflow;
  },
  CLEAR_WORKFLOW_DATA(state) {
    state.workflowData = {};
  },
  MUTATE_WORKFLOW_DATA(state, { index, value }) {
    if (!state.workflowData || !state.workflowData.stepsData || !state.workflowData.stepsData[index]) {
      console.warn("Not found mapping step");
      return;
    }
    Vue.set(state.workflowData.stepsData, index, value);
  },
  SET_IS_WORKFLOW_URLS_UPDATED(state, isUpdated) {
    state.isWorkflowURLsUpdated = isUpdated;
  },
  SET_VIDEO_ELEMENT(state, videoElement) {
    state.videoElement = videoElement;
  },
  SET_CURRENT_REPEAT_TYPE(state, type) {
    state.currentRepeatType = type;
  },
  SET_QUIZ_INFO(state, { userQuizId, status }) {
    if (state.workflowData.usersQuizzes) {
      Vue.set(state.workflowData.usersQuizzes, "userQuizId", userQuizId);
      Vue.set(state.workflowData.usersQuizzes, "status", status);
    }
  },
};

export const actions = {
  setIsQaTabOpen({ commit }, { isOpen }) {
    commit("SET_IS_QA_TAB_OPEN", isOpen);
  },
  enterMobileFullscreen({ commit }) {
    commit("ENTER_MOBILE_FULLSCREEN");
  },
  exitMobileFullscreen({ commit }) {
    commit("EXIT_MOBILE_FULLSCREEN");
  },
  enterDesktopFullscreen({ commit }) {
    commit("ENTER_DESKTOP_FULLSCREEN");
  },
  exitDesktopFullscreen({ commit }) {
    commit("EXIT_DETSKTOP_FULLSCREEN");
  },
  changeTheaterMode({ commit, state }) {
    if (state.isTheaterMode) {
      commit("EXIT_DETSKTOP_THEATER_MODE");
    } else {
      commit("ENTER_DESKTOP_THEATER_MODE");
    }
  },
  enterTheaterMode({ commit }) {
    commit("ENTER_DESKTOP_THEATER_MODE");
  },
  setCurrentStepIdx({ commit }, { index }) {
    commit("SET_CURRENT_STEP_IDX", index);
  },
  setIsStartTyping({ commit }) {
    commit("SET_IS_START_TYPING");
  },
  setIsEndTyping({ commit }) {
    commit("SET_IS_END_TYPING");
  },
  showErrorMessage({ commit }) {
    commit("SHOW_ERROR_MESSAGE");
  },
  hideErrorMessage({ commit }) {
    commit("HIDE_ERROR_MESSAGE");
  },
  setSelectedAttachmentStep({ commit }, { step }) {
    commit("SET_SELECTED_ATTACHMENT_STEP", step);
  },
  openAttachmentsPopup({ commit }, { selectedDiagramIdx, isOpen }) {
    commit("SET_ATTACHMENTS_POPUP", { selectedDiagramIdx, isOpen });
  },
  closeAttachmentsPopup({ commit }) {
    commit("SET_ATTACHMENTS_POPUP", { selectedDiagramIdx: -1, isOpen: false });
  },
  clearWorkflowData({ commit }) {
    commit("CLEAR_WORKFLOW_DATA");
  },
  clearAllWorkflowPlayerData({ commit }) {
    commit("CLEAR_WORKFLOW_DATA");
    commit("SET_CURRENT_STEP_IDX", -1);
    commit("SET_SELECTED_ATTACHMENT_STEP", {});
    commit("HIDE_ERROR_MESSAGE");
    commit("SET_VIDEO_ELEMENT", null);
    commit("SET_CURRENT_REPEAT_TYPE", REPEAT_TYPES.DISABLE);
  },
  async fetchWorkflowData({ dispatch, commit }, { workflowId, isPublic }) {
    commit("CLEAR_WORKFLOW_DATA");
    const params = {
      workflowId,
      withAddons: true,
    };
    // retry up to 3 times
    let retry = 3;
    let isSuccess = false;
    for (let i = 0; i < retry; i++) {
      let ok, data, errorMessage;
      if (isPublic) {
        ({ ok, data, errorMessage } = await fetchPublicWorkflowPlayerData(params));
      } else {
        ({ ok, data, errorMessage } = await fetchWorkflowPlayerData(params));
      }
      if (ok) {
        const workflow = data.item;
        commit("STORE_WORKFLOW_DATA", { workflow });
        dispatch("checkUnsignedURLs", { isPublic });
        isSuccess = true;
        break;
      } else {
        console.error(`Failed to fetch workflow data on attempt ${i + 1}. error: `, errorMessage);
      }
    }
    return { isSuccess };
  },
  async checkUnsignedURLs({ dispatch, state, rootGetters }, { isPublic }) {
    const is_gen2 = rootGetters["getter_is_gen2"];
    const stepsData = state.workflowData.stepsData;
    let unsignedURLs = [];
    const workflowId = state.workflowData.id;
    stepsData.forEach((step) => {
      const stepId = step.id;
      if (is_gen2 && step.video) {
        // gen2 use step.video to play video
        const isSigned = checkIsSignedUrl(step.video);
        if (!isSigned) {
          unsignedURLs.push({
            workflowId,
            stepId,
            type: "video",
          });
        }
      } else {
        // gen1 use step.hlsUrl / step.videos to play video
        if (step.hlsUrl) {
          const isSigned = checkIsSignedUrl(step.hlsUrl);
          if (!isSigned) {
            unsignedURLs.push({
              workflowId,
              stepId,
              type: "videoRef",
            });
          }
        }
        if (step.videos && Object.keys(step.videos).length > 0) {
          let videosList = [];
          Object.entries(step.videos).forEach((entry) => {
            const [key, url] = entry;
            const isSigned = checkIsSignedUrl(url);
            if (!isSigned) {
              videosList.push(key);
            }
          });
          if (videosList.length > 0) {
            unsignedURLs.push({
              workflowId,
              stepId,
              type: "videos",
            });
          }
        }
      }
      if (step.poster) {
        const isSigned = checkIsSignedUrl(step.poster);
        if (!isSigned) {
          unsignedURLs.push({
            workflowId,
            stepId,
            type: "poster",
          });
        }
      }
      if (step.attachments && Object.keys(step.attachments).length > 0) {
        let attachmentsList = [];
        const diagrams = step.attachments.diagrams;
        diagrams.forEach((diagram, idx) => {
          const isSigned = checkIsSignedUrl(diagram);
          if (!isSigned) {
            attachmentsList.push({
              key: "diagrams",
              index: idx,
            });
          }
        });
        const pdfs = step.attachments.pdfs;
        pdfs.forEach((pdf, idx) => {
          const isSigned = checkIsSignedUrl(pdf.link);
          if (!isSigned) {
            attachmentsList.push({
              key: "pdfs",
              index: idx,
            });
          }
        });
        if (attachmentsList.length > 0) {
          unsignedURLs.push({
            workflowId,
            stepId,
            type: "attachments",
          });
        }
      }
    });
    if (unsignedURLs.length === 0) {
      return;
    }
    const { ok, data, error } = await getBatchSignedURLs({ data: unsignedURLs, isPublic });
    if (ok) {
      const newURLs = data.data.item;
      dispatch("updateUnsignedURLs", { newURLs });
    } else {
      console.error("Failed to get signed URLs", error);
    }
  },
  updateUnsignedURLs({ commit, state, dispatch, rootGetters }, { newURLs }) {
    if (newURLs.length === 0) {
      return;
    }
    const is_gen2 = rootGetters["getter_is_gen2"];
    const stepsData = state.workflowData.stepsData;
    let madeChangesVideos = false;
    for (const newURL of newURLs) {
      const stepIndex = stepsData.findIndex((step) => step.id === newURL.stepId);
      if (stepIndex === -1) {
        console.warn("Not found mapping step for sign api response");
        continue;
      }
      let madeChanges = false;
      let newStep = stepsData[stepIndex];
      if (is_gen2 && newURL.type === "video" && newURL.message === "OK") {
        // gen2 use step.video to play video
        newStep.video = newURL.signed;
        madeChanges = true;
        madeChangesVideos = true;
      } else {
        // gen1 use step.hlsUrl / step.videos to play video
        if (newURL.type === "videoRef" && newURL.message === "OK") {
          newStep.hlsUrl = newURL.signed;
          madeChanges = true;
          madeChangesVideos = true;
        } else if (newURL.type === "videos" && Object.keys(newURL.videos).length > 0) {
          Object.entries(newURL.videos).forEach((entry) => {
            const [key, url] = entry;
            if (url.message === "OK") {
              newStep.videos[key] = newURL.videos[key].signed;
              madeChanges = true;
              madeChangesVideos = true;
            }
          });
        }
      }
      if (newURL.type === "poster" && newURL.message === "OK") {
        newStep.poster = newURL.signed;
        madeChanges = true;
      } else if (newURL.type === "attachments") {
        const diagrams = newURL.attachments.diagrams;
        diagrams.forEach((diagram, idx) => {
          if (diagram.message === "OK") {
            Vue.set(newStep.attachments["diagrams"], idx, diagram.signed);
            madeChanges = true;
          }
        });
        const pdfs = newURL.attachments.pdfs;
        pdfs.forEach((pdf, idx) => {
          if (pdf.message === "OK") {
            const newPdf = newStep.attachments["pdfs"][idx];
            newPdf.link = pdf.signed;
            Vue.set(newStep.attachments["pdfs"], idx, newPdf);
            madeChanges = true;
          }
        });
      }
      if (madeChanges) {
        commit("MUTATE_WORKFLOW_DATA", { index: stepIndex, value: newStep });
      }
    }
    if (madeChangesVideos) {
      dispatch("updatedWorkflowURLs");
    }
  },
  updatedWorkflowURLs({ commit }) {
    commit("SET_IS_WORKFLOW_URLS_UPDATED", true);
    setTimeout(() => {
      commit("SET_IS_WORKFLOW_URLS_UPDATED", false);
    }, 300);
  },
  setVideoElement({ commit }, videoElement) {
    commit("SET_VIDEO_ELEMENT", videoElement);
  },
  enterPictureInPictureMode({ state }) {
    const videoElement = state.videoElement;
    if (!videoElement) return;
    if (videoElement !== document.pictureInPictureElement) {
      videoElement.requestPictureInPicture();
    }
  },
  exitPictureInPictureMode() {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  },
  changeRepeatType({ commit }, payload = { type }) {
    commit("SET_CURRENT_REPEAT_TYPE", payload.type);
  },
  setQuizResult({ commit, state }, payload) {
    if (state.workflowData.id === payload.workflowId) {
      commit("SET_QUIZ_INFO", payload);
    }
  },
};
